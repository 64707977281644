import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const PDF: React.SFC<IconProps> = (props) => {
    const {width, height} = props;

    return (
        <IconBase viewPortWidth={18} viewPortHeight={20} height={height} width={width}>
            <g fill="#3F3F3C" fillRule="nonzero">
                <path d="M5.443 9.669H4.87v1.188h-.585V7.143H5.45c.34-.033.68.07.943.286.215.26.317.595.286.931.013.87-.399 1.307-1.237 1.309zm-.074-2h-.486v1.474h.486a.751.751 0 0 0 .557-.177.811.811 0 0 0 .174-.572.711.711 0 0 0-.177-.551.834.834 0 0 0-.554-.172V7.67z"/>
                <path d="M5.026 10.997H4.15v-4H5.46c.379-.03.754.093 1.043.34.254.284.382.66.354 1.04 0 .937-.483 1.429-1.4 1.429h-.431v1.191zm-.589-.286h.303V9.526h.703c.76 0 1.114-.366 1.114-1.143a1.143 1.143 0 0 0-.254-.829 1.163 1.163 0 0 0-.843-.257H4.437v3.414zm.932-1.428H4.74V7.54h.629c.23-.017.46.05.645.189a.84.84 0 0 1 .229.66.931.931 0 0 1-.217.674.891.891 0 0 1-.657.208v.012zm-.343-.286h.343a.629.629 0 0 0 .454-.14.677.677 0 0 0 .134-.474.571.571 0 0 0-.128-.443.7.7 0 0 0-.46-.12h-.343v1.177zM7.714 10.857V7.143h.857c.243-.006.485.02.72.074.18.049.341.148.466.286.146.16.245.358.286.571.06.337.085.679.077 1.02.013.36-.04.72-.16 1.06a.994.994 0 0 1-.483.572 2.154 2.154 0 0 1-.906.131h-.857zm1.595-.751a.823.823 0 0 0 .18-.406c.035-.232.05-.466.045-.7 0-.239-.017-.478-.051-.714a.814.814 0 0 0-.169-.403A.571.571 0 0 0 9 7.714a2.746 2.746 0 0 0-.531-.04h-.183v2.686h.168c.176-.003.35-.027.52-.074a.623.623 0 0 0 .32-.183l.015.003z"/>
                <path d="M8.571 10.997H7.557v-4h1.014c.255-.005.51.022.758.08.204.053.39.163.534.317a1.3 1.3 0 0 1 .306.629c.064.346.092.699.082 1.051.013.38-.045.757-.171 1.115a1.143 1.143 0 0 1-.554.631 2.226 2.226 0 0 1-.955.177zm-.728-.286h.728a2 2 0 0 0 .858-.14.857.857 0 0 0 .414-.482c.11-.324.161-.665.148-1.006a5.066 5.066 0 0 0-.074-.989A1.04 1.04 0 0 0 9.68 7.6a.8.8 0 0 0-.4-.24 2.874 2.874 0 0 0-.709-.069h-.728v3.42zm.614-.222h-.311V7.517h.323c.191-.006.383.01.571.046a.711.711 0 0 1 .389.237c.11.137.179.303.2.477.031.239.045.48.04.72 0 .24-.017.48-.055.717a.986.986 0 0 1-.211.472.769.769 0 0 1-.392.24 2.384 2.384 0 0 1-.554.063zm-.026-.286h.026c.162.004.324-.01.483-.043a.48.48 0 0 0 .249-.151.694.694 0 0 0 .142-.338c.036-.223.051-.448.046-.674a4.6 4.6 0 0 0-.037-.677.666.666 0 0 0-.134-.32.434.434 0 0 0-.237-.146 2.431 2.431 0 0 0-.5-.037H8.43v2.386zM11.251 10.857V7.143h2.178v.534H11.84v1.254h1.351v.509H11.84v1.417z"/>
                <path d="M11.983 10.997h-.874v-4h2.46v.82h-1.586v.969h1.351v.794h-1.351v1.417zm-.589-.286h.303V9.303h1.352V9.08h-1.352V7.54h1.586v-.249h-1.889v3.42z"/>
                <path d="M17.714 20H0V0h13.226l4.488 4.469V20zM1.143 18.857H16.57V4.943l-3.82-3.8H1.143v17.714z"/>
                <path d="M4.309 13.154h9.126v1.143H4.309z"/>
            </g>
        </IconBase>
    );
};
