import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const HouseActive: React.SFC<IconProps> = (props) => {
    const {width, height} = props;

    return (
        <IconBase viewPortWidth={65} viewPortHeight={67} height={height} width={width}>
            <g>
                <defs>
                    <path id="house-active-b" d="M1.063 31l28-30 11 11V5h6v13l12 12-1 1-3-3v32h-50l1-32-3 3z"/>
                    <filter id="house-active-a" width="124.6%" height="123.7%" x="-12.3%" y="-8.5%" filterUnits="objectBoundingBox">
                        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
                        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"/>
                        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.247058824 0 0 0 0 0.247058824 0 0 0 0 0.235294118 0 0 0 0.25 0"/>
                    </filter>
                </defs>
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(2.938 1)">
                        <use fill="#000" filter="url(#house-active-a)" xlinkHref="#house-active-b"/>
                        <use fill="#FFF" xlinkHref="#house-active-b"/>
                    </g>
                    <path fill="currentColor" d="M61.677 31.696L49.27 18.913V5.348h-6.74v6.609L32.422 1.522 3.2 31.696l1.196 1.234 2.78-2.87v30.888h50.518V30.026l2.814 2.904 1.17-1.234zm-23.36 27.513H26.525V38.487h11.794v20.722z"/>
                </g>
            </g>
        </IconBase>
    );
};
