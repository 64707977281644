import * as React from 'react';
import {useState} from 'react';
import {A} from 'components/atoms/A';
import classnames from 'classnames';
import {DataTableColumn} from 'components/molecules/DataTable/index';
import {Icon} from 'components/atoms/Icon';

interface Props {
    data: any;
    index: number;
    columns: DataTableColumn[];
    onRowClick?: (e: any) => any;
    childRowAccessor?: string;
    childRowColumns?: DataTableColumn[];
    isInEditMode?: boolean;
    rowIsActive: boolean;
    getCellStyle: (column: DataTableColumn) => any;
    onChildRowClick?: (data: any) => (e: any) => any;
}

export const FoldableTableRow: React.FunctionComponent<Props> = ({
    data,
    index,
    rowIsActive,
    columns,
    onRowClick,
    childRowAccessor,
    childRowColumns,
    isInEditMode,
    onChildRowClick,
    getCellStyle
}) => {

    let [folded, setFolded] = useState<boolean>(true);

    // Row is not draggable
    //let url;
    let route = true;

    if (typeof data.url !== 'undefined' && data.url !== '') {
        route = true;
        //url = `${data.url}`;
    } else {
        route = false;
        //url = `${data.externalUrl}`;
    }

    //url = isInEditMode ? `${url}?editMode=${IS_IN_EDIT_MODE}` : `${url}`;

    //let rowIsActive = this.shouldHaveActiveClass(data);

    let classes = classnames('data-table__row', 'no-styles', {
        // @ts-ignore
        'data-table__row--active': rowIsActive,
    });

    let rowPackClasses = classnames('data-table__pack', {
        'data-table__pack--open': childRowAccessor && !folded,
        'data-table__pack--closed': childRowAccessor && folded,
    });

    const toggleFold = () => {
        setFolded(!folded);
    };

    const getOnClickFunction = (e) => {

        //if(!data[childRowAccessor] || data[childRowAccessor].length === 0) return;

        if(childRowAccessor) {
            e.preventDefault();
            return toggleFold();
        }

        return onRowClick && onRowClick(e);
    };

    return (
        <div className={rowPackClasses}>
            <A
                href={"#"}
                route={route}
                className={classes}
                onClick={getOnClickFunction}
                key={`${data}-${index}`}
                data-test-id="data-table-row"
            >
                {columns.map((column, index) => {
                    if(column.hide) return null;

                    let cellClasses = classnames('data-table__cell', {
                        'data-table__cell--left-align': column.leftAlign,
                        'data-table__cell--mobile-hide': column.notMobile,
                    });

                    return (
                        <div
                            className={cellClasses}
                            key={`${column}-${data[column.accessor]}-${index}`}
                            style={getCellStyle(column)}
                        >
                            <span>
                                {(index === 0 && childRowAccessor && data[childRowAccessor] && data[childRowAccessor].length > 0) && <span><Icon name={folded ? "plus" : "minus"} className="data-table__fold-icon" sizeM /></span>}
                                {(column.accessorRenderer) ? column.accessorRenderer(data[column.accessor], data) : data[column.accessor]} {column.accessorAffix && column.accessorAffix}
                            </span>
                        </div>
                    );
                })}
            </A>

            {childRowAccessor &&
                <div className="data-table__child-rows">
                    {(data[childRowAccessor]) && data[childRowAccessor].map((childData, index) => {

                        return (
                            <div key={`row-row-${index}`} className="data-table__row" onClick={onChildRowClick && onChildRowClick(childData)}>
                                {childRowColumns && childRowColumns.map((column, index) => {


                                    if (column.hide) return null;
                                    if (!childRowAccessor) return null;

                                    let cellClasses = classnames('data-table__cell', 'data-table__child-cell', {
                                        'data-table__cell--left-align': column.leftAlign,
                                        'data-table__cell--second-row': column.secondRow,
                                        'data-table__cell--first-row': !column.secondRow,
                                    });

                                    return (
                                        <div className={cellClasses}
                                             key={`${column}-${data[column.accessor]}-${index}`}
                                             style={getCellStyle(column)}>
                                            <span>{(column.accessorRenderer) ? column.accessorRenderer(childData[column.accessor], data[childRowAccessor]) : childData[column.accessor]} {column.accessorAffix && column.accessorAffix}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        )

                    })}
                </div>
            }
        </div>
    );
};
