import * as React from 'react';

import classnames from 'classnames';

import './style.scss';


interface Props {
    children: any;
    id?:string;
    className?: string;
    largeMargin?: boolean;
    center?: boolean;
    onClick?: any;
}

export class H2 extends React.PureComponent<Props> {
    render() {

        let headerClasses = classnames(this.props.className, {
            'large-margin': this.props.largeMargin,
            'center': this.props.center,
        });

        return (
            <h2 id={this.props.id} className={headerClasses} onClick={this.props.onClick}>
                { this.props.children }
            </h2>
        )
    }
}
