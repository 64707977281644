import * as React from 'react';
import {Container} from 'components/atoms/Container';
import {H2} from "../../atoms/H2";
import {P} from "../../atoms/P";
import {A} from "../../atoms/A";

import './style.scss';
import { getSignStatus } from 'services/documents/documents-service';
import { CooperativeSignStauts } from 'services/documents/types';
import { Loader } from 'components/atoms/Loader';

interface Props {

}

export interface SignStatus {
    loading: boolean;
}

export default class ThankYouPage extends React.PureComponent<Props> {

    _interval;
    _intervalCount = 1;

    state = {
        loading: true,
    };

    async componentDidMount() {

        if(window.location.href.indexOf('taskid=') > -1)
        {
            this._interval = setInterval(async () => {

                let taskId = window.location.search.replace('?taskid=', '');
                let response: CooperativeSignStauts = await getSignStatus(taskId);
    
                if (response && response.isSigned){
                    window.location.href = "/";
                }

                if (this._intervalCount > 5) {
                    this.setState({
                        loading: false,
                    }); 

                    clearInterval(this._interval);
                }

                this._intervalCount++;

            }, 3000);

        }
    }

    render() {

        if(this.state.loading) return <Loader text="Tack! Signeringen för avtalet behandlas i systemet."/>;

        return (
            <Container>
                <div className="content">
                    <H2 center>Tack! Signeringen för avtalet behandlas i systemet.</H2>
                    <P>
                        <A href="/">Tillbaka till Brf webben</A>
                    </P>
                </div>

            </Container>
        )
    }

}