import * as React from 'react';
import {IconBase} from 'components/atoms/Icons/_iconBase';
import {IconProps} from 'components/atoms/Icons/types';

export const LookingGlas: React.SFC<IconProps> = (props) => {
    const {height, width} = props;

    return (
        <IconBase fill viewPortWidth={20} viewPortHeight={20} height={height} width={width}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g fill="#3F3F3C" fillRule="nonzero">
                    <g>
                        <polygon points="17.9714286 19.9885714 11.8085714 13.8257143 12.6171429 13.0171429 17.9714286 18.3714286 18.3742857 17.9685714 13.04 12.6342857 13.8485714 11.8257143 19.9914286 17.9685714"/>
                        <path d="M7.84857143,15.4285714 C3.58808907,15.4285714 0.134285714,11.9747681 0.134285714,7.71428571 C0.134285714,3.45380336 3.58808907,7.61295788e-16 7.84857143,0 C12.1090538,-7.61295788e-16 15.5628571,3.45380336 15.5628571,7.71428571 C15.5628571,9.76024149 14.7501044,11.7224002 13.3033952,13.1691095 C11.856686,14.6158187 9.89452721,15.4285714 7.84857143,15.4285714 Z M7.84857143,1.14285714 C4.21927164,1.14285714 1.27714286,4.08498593 1.27714286,7.71428571 C1.27714286,11.3435855 4.21927164,14.2857143 7.84857143,14.2857143 C11.4778712,14.2857143 14.42,11.3435855 14.42,7.71428571 C14.42,4.08498593 11.4778712,1.14285714 7.84857143,1.14285714 Z" />
                    </g>
                </g>
            </g>
        </IconBase>
    );
};

