import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';

import swishHorizontal from './assets/bankid-white.png';


export const BankIDWhite: React.SFC<IconProps> = (props) => {
    const {width, height} = props;

    return (
        <img src={swishHorizontal} width={width} height={height} alt='' />

    );
};
