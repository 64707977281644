import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const Showing: React.FunctionComponent<IconProps> = (props) => {
    const {height, width} = props;

    return (
        <IconBase viewPortWidth={26} viewPortHeight={30} height={height} width={width}>
            <g transform="translate(-3.000000, -0.000000)" stroke="none" strokeWidth="1" fill="#3F3F3C" fillRule="evenodd">
                <path d="M24.7446667,1.76470588 L24.7446667,0 L23.878,0 L23.878,1.76470588 L16.4636667,1.76470588 L16.4636667,0 L15.597,0 L15.597,1.76470588 L8.2,1.76470588 L8.2,0 L7.33333333,0 L7.33333333,1.76470588 L3,1.76470588 L3,30 L29,30 L29,1.76470588 L24.7446667,1.76470588 Z M7.33333333,2.64705882 L7.33333333,4.41176471 L8.2,4.41176471 L8.2,2.64705882 L15.597,2.64705882 L15.597,4.41176471 L16.4636667,4.41176471 L16.4636667,2.64705882 L23.878,2.64705882 L23.878,4.41176471 L24.7446667,4.41176471 L24.7446667,2.64705882 L28.1333333,2.64705882 L28.1333333,7.05882353 L3.86666667,7.05882353 L3.86666667,2.64705882 L7.33333333,2.64705882 Z M10.5,20 C9.67157288,20 9,19.3284271 9,18.5 C9,17.6715729 9.67157288,17 10.5,17 C11.3284271,17 12,17.6715729 12,18.5 C12,19.3284271 11.3284271,20 10.5,20 Z M16.5,20 C15.6715729,20 15,19.3284271 15,18.5 C15,17.6715729 15.6715729,17 16.5,17 C17.3284271,17 18,17.6715729 18,18.5 C18,19.3284271 17.3284271,20 16.5,20 Z M22.5,20 C21.6715729,20 21,19.3284271 21,18.5 C21,17.6715729 21.6715729,17 22.5,17 C23.3284271,17 24,17.6715729 24,18.5 C24,19.3284271 23.3284271,20 22.5,20 Z M3.86666667,29.1176471 L3.86666667,7.94117647 L28.1333333,7.94117647 L28.1333333,29.1176471 L3.86666667,29.1176471 Z"></path>
            </g>
        </IconBase>
    );
};
