import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const Umbrella: React.SFC<IconProps> = (props) => {
    const {height, width} = props;

    return (
        <IconBase viewPortWidth={30} viewPortHeight={30} height={height} width={width}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-1.000000, 0.000000)" fill="#3F3F3C" fillRule="nonzero">
                    <g transform="translate(1.000000, 0.000000)">
                        <path d="M15.2173913,1.73913043 L15.2173913,0 L14.3478261,0 L14.3478261,1.73913043 C6.39565217,1.96086957 0,8.3173913 0,16.0869565 L0,16.5217391 L0.9,16.5217391 L1.02173913,16.3 C1.88136819,14.7729351 3.49760465,13.8280365 5.25,13.8280365 C7.00239535,13.8280365 8.61863181,14.7729351 9.47826087,16.3 L9.60434783,16.5217391 L10.4086957,16.5217391 L10.5347826,16.3 C11.330678,14.9159499 12.7565056,14.0120045 14.3478261,13.8826087 L14.3478261,25.3173913 C14.3478261,27.1855545 12.8333806,28.7 10.9652174,28.7 C9.09705419,28.7 7.5826087,27.1855545 7.5826087,25.3173913 L6.71304348,25.3173913 C6.71304348,27.6658021 8.61680659,29.5695652 10.9652174,29.5695652 C13.3136282,29.5695652 15.2173913,27.6658021 15.2173913,25.3173913 L15.2173913,13.8869565 C16.7956479,14.0392409 18.2006877,14.9510566 18.9826087,16.3304348 L19.1043478,16.5521739 L19.9130435,16.5521739 L20.0391304,16.3304348 C20.8987595,14.8033698 22.514996,13.8584713 24.2673913,13.8584713 C26.0197867,13.8584713 27.6360231,14.8033698 28.4956522,16.3304348 L28.6217391,16.5521739 L29.5652174,16.5521739 L29.5652174,16.1173913 C29.5652174,8.33478261 23.1695652,1.97826087 15.2173913,1.73913043 Z M24.2652174,12.9869565 C22.3562133,12.9896185 20.5740837,13.9434573 19.5130435,15.5304348 C18.4532688,13.9422556 16.6701719,12.9884651 14.7608696,12.9884651 C12.8515672,12.9884651 11.0684703,13.9422556 10.0086957,15.5304348 C9.01559409,14.0443158 7.38506525,13.107665 5.60100432,12.9984542 C3.81694339,12.8892434 2.08434336,13.6200211 0.917391304,14.973913 C1.5173913,8.06086957 7.50869565,2.60869565 14.7826087,2.60869565 C22.0565217,2.60869565 28.0826087,8.08695652 28.6521739,15.0304348 C27.5622212,13.7363654 25.9571421,12.9887072 24.2652174,12.9869565 Z" />
                    </g>
                </g>
            </g>
        </IconBase>
    );
};
