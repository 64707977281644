import * as React from 'react';

import './style.scss';
import {DataTable} from 'components/molecules/DataTable';
import {A} from "components/atoms/A";
import {formatTableDate} from "services/utils/date-helper";
import {RouteComponentProps, withRouter} from "react-router";
import {CooperativeTask} from 'services/documents/types';
import {H3} from "../../../atoms/H3";

interface Props extends RouteComponentProps{
    title: string;
    cooperativeId: string;
    data: CooperativeTask[];
}

export class BrfTableComp extends React.PureComponent<Props> {

    _columns: any;

    _defaultSorted: any = {
        id: 'residenceObjectNumber',
        desc: false,
    };

    componentWillMount(): void {
        this._columns = this.getColumns();
    }

    renderAsDate = (value: Date, data: any) => {
        return formatTableDate(value);
    };

    onRowClick = (data) => (e) => {
        e.preventDefault();

        this.props.history.push(`/object-page/${this.props.cooperativeId}/${data.residenceObjectId}`);
    };

    getColumns = () => {
        let columnAgreementName = {
            name: 'Avtal att signera',
            accessor: 'contractType',
        };

        let columnObjectNumber = {
            name: 'Objektsnummer',
            accessor: 'residenceObjectNumber',
        };

        let columnDate = {
            name: 'Skapat',
            accessor: 'cooperativeSigningStarted',
            accessorRenderer: this.renderAsDate,
        };

        return [
            columnAgreementName,
            columnObjectNumber,
            columnDate,
        ]
    };

    render() {

        if(!this._columns) return null;


        return (
            <div className="available-table-container">

                <H3>{this.props.title}</H3>

                {(this.props.data && this.props.data.length === 0) && <span className="available-table-container__no-contracts">Du har inga nya avtal att signera.</span>}

                {(this.props.data && this.props.data.length > 0) &&
                    <DataTable
                        data={this.props.data}
                        defaultSorted={this._defaultSorted}
                        onRowClick={this.onRowClick}
                        columns={this._columns}
                        foldable
                        foldableCount={10}
                        foldableText="Visa alla dokument"
                    />
                }


                <A route href={`/archive/${this.props.cooperativeId}`}>Se alla avtal</A>

            </div>
        )
    }
}

export const BrfTable = withRouter(BrfTableComp);
