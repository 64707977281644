import * as React from 'react';

import classNames from 'classnames';

import './style.scss';

interface Props {
    children: any;
    id?:string;
    className?: string;
    narrow?: boolean;
    noContainer?: boolean;
}

export class Container extends React.PureComponent<Props> {
    render() {

        const divClassName = classNames(this.props.className, {
            'container': !this.props.noContainer,
            'container--narrow': this.props.narrow,
        });

        return (
            <div id={this.props.id} className={divClassName}>
                { this.props.children }
            </div>
        )
    }
}
