import * as React from 'react';

import swishVertical from './assets/swish-vertical-main.png';

interface Props {}

export const PaymentSwishLarge: React.SFC<Props> = (props) => {

    return (
        <img src={swishVertical} alt='' />
    );
};
