import * as React from 'react';

import './style.scss';
import {useRef, useState} from "react";
import {Input} from "../../atoms/formElements/Input";
import {Button} from "../../atoms/Button";
import {A} from "../../atoms/A";
import {Icon} from "../../atoms/Icon";
import {sendCooperativeNote} from "../../../services/documents/documents-service";

interface Props {
    objectId: string;
    contractId: string;
    note?: string;
}

export const ObjectNoteModule: React.FunctionComponent<Props> = ({ objectId, contractId, note = '' }) => {

    let [toggled, setToggled] = useState<boolean>(false);
    let [noteValue, setNoteValue] = useState<string>(note ? note : '');
    //let [loading, setLoading] = useState<boolean>(false);
    let [messageSent, setMessageSent] = useState<boolean>(false);

    let _inputReference = useRef<HTMLInputElement>(null);

    const onTogglerClick = () => {
        setToggled(true);


        if(_inputReference.current) {
            setTimeout(() => {
                _inputReference.current.focus();
            })
        }
    }

    const onNoteChange = (value: string, name: string) => {
        setNoteValue(value);
    }

    const onSendNote = async () => {

        if(noteValue.length === 0) return;

        //setLoading(true);

        await sendCooperativeNote(objectId, contractId, noteValue);

        //setLoading(false);
        setToggled(false);
        setMessageSent(true);

    }

    const onCancelNote = () => {
        setToggled(false);
        setNoteValue(note);
    }

    return (
        <div className="object-note-module">

            <span className={`object-note-module__toggler ${!toggled && !note && !messageSent ? 'show' : 'hide'}`} onClick={onTogglerClick}>
                <Icon name="edit" />
                <span className="toggler-label">Anteckning till BRF-ledamot</span>
            </span>

            <div className={`object-note-module__toggler-edit ${!toggled && noteValue ? 'show' : 'hide'}`}>
                <span className={`object-note-module__toggler`} onClick={onTogglerClick}>
                    <Icon name="edit" />
                    <span className="toggler-label">Redigera anteckning</span>
                </span>

                <div className="object-note-module__note">
                    <strong>Anteckning till BRF-ledamot</strong>
                    <p>
                        <span style={{whiteSpace: "pre-line"}}>{noteValue}</span>
                    </p>
                </div>
            </div>

            <div className={`object-note-module__form ${toggled ? 'show' : 'hide'}`}>
                <Input type="text" textarea id="object-note" name="object-note" messages={[]} innerRef={_inputReference} value={noteValue} onChange={onNoteChange} placeholder="Anteckning till BRF-ledamot" />
            </div>

            <div className={`object-note-module__buttons ${toggled ? 'show' : 'hide'}`}>
                <Button primary small onClick={onSendNote}>Spara</Button>
                <A href="#" onClick={onCancelNote}>Avbryt</A>
            </div>


        </div>
    );
};
