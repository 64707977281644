import * as React from 'react';
import {IconBase} from 'components/atoms/Icons/_iconBase';
import {IconProps} from 'components/atoms/Icons/types';

export const FavoriteFilled: React.SFC<IconProps> = (props) => {
    const {height, width} = props;

    return (
        <IconBase fill viewPortWidth={20} viewPortHeight={20} height={height} width={width}>
            <g fill="#3F3F3C">
            <path d="M10,19.8347826 L1.66666667,10.5449275 C0.675362973,9.38030782 0.133011147,7.8735646 0.144444444,6.31594203 C0.144444444,2.91304348 2.67222222,0.144927536 5.77777778,0.144927536 C7.4134877,0.155026229 8.96037827,0.922807843 10,2.24057971 C11.0421032,0.919697112 12.5937285,0.151586786 14.2333333,0.144927536 C17.3388889,0.144927536 19.8666667,2.91304348 19.8666667,6.31594203 C19.8765821,7.87798984 19.3301369,9.3882034 18.3333333,10.5536232 L18.2416667,10.6637681 L10,19.8347826 Z"></path>
            </g>
        </IconBase>
    );
};
