import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const AppartmentActive: React.SFC<IconProps> = (props) => {
    const {height, width} = props;

    return (
        <IconBase viewPortWidth={60} viewPortHeight={68} height={height} width={width}>
            <g>
                <defs>
                    <path id="apartment-active-b" d="M18 14V0h34v60H0V14z"/>
                    <filter id="apartment-active-a" width="126.9%" height="123.3%" x="-13.5%" y="-8.3%" filterUnits="objectBoundingBox">
                        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
                        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"/>
                        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.247058824 0 0 0 0 0.247058824 0 0 0 0 0.235294118 0 0 0 0.25 0"/>
                    </filter>
                </defs>
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(4 2)">
                        <use fill="#000" filter="url(#apartment-active-a)" xlinkHref="#apartment-active-b"/>
                        <use fill="#FFF" xlinkHref="#apartment-active-b"/>
                    </g>
                    <path fill="currentColor" d="M21.538 2v13.043H4V62h52.615V2H21.538zm3.693 58.154h-7.385V49.077h7.385v11.077zm13.846-11.077h3.692v11.077h-3.692V49.077zm15.692 11.077H44.308V47.368h-5.231v-32.26H23.385V3.846h31.384v56.308z"/>
                </g>
            </g>
        </IconBase>
    );
};
