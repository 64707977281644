import * as React from 'react';

import './style.scss';
import {Logo} from 'components/atoms/Logo';
import {MainGrid} from 'components/atoms/MainGrid';
import {A} from 'components/atoms/A';
import { AuthenticationQrConsumer, AuthenticationQrContextType } from 'services/authentication/authentication-qr-context';

interface Props {
}

export class Header extends React.PureComponent<Props> {


    render() {
        return (
            <div className="header">
                <MainGrid>
                    <div className="header__logo">
                        <A href="/" className="no-styles">
                            <Logo />
                        </A>
                    </div>

                    <div className="header__user-info">


                        <AuthenticationQrConsumer>
                            {(value: AuthenticationQrContextType) => {

                                return (
                                    <div>
                                        {value.loggedIn &&
                                        <React.Fragment>
                                            {/*Andreas Jansson<br />
                                            andreas.jansson@jm.se<br />*/}
                                            <A href="#" onClick={value.setLogout}>Logga ut</A>
                                        </React.Fragment>}
                                    </div>
                                )

                            }}
                        </AuthenticationQrConsumer>
                    </div>
                </MainGrid>
            </div>
        )
    }
}