import * as React from 'react';

import './style.scss';
import {DataTable} from 'components/molecules/DataTable';
import {formatTableDate} from "services/utils/date-helper";
import {Icon} from "components/atoms/Icon";
import {CooperativeDocument} from 'services/documents/types';
import {downloadDocument} from 'services/documents/documents-service';
import {P} from 'components/atoms/P';

interface Props {
    data: CooperativeDocument[];
    objectId: string;
}

interface State {
    downloading: boolean;
}

export class DocumentTable extends React.PureComponent<Props, State> {

    _columns: any;

    _defaultSorted: any = {
        id: 'title',
        desc: false,
    };

    state = {
        downloading: false,
    };

    componentWillMount(): void {
        this._columns = this.getColumns();
    }

    renderAsDate = (value: Date, data: any) => {
        return formatTableDate(value);
    };

    renderWithIcon = (value: string, data: any) => {

        if(!data.documentId) return value;

        return (
            <React.Fragment>
                <Icon name="pdf" />
                {value}
            </React.Fragment>
        )
    };

    onDocumentClick = (data: CooperativeDocument) => async (e: any) => {
        if(!data.documentId) return;

        this.setState({
            downloading: true,
        }, async () => {
            await downloadDocument(this.props.objectId ,data.documentId, data.title);

            this.setState({
                downloading: false,
            });
        })
    };

    getColumns = () => {
        let columnAgreementName = {
            name: 'Dokument',
            accessor: 'title',
            accessorRenderer: this.renderWithIcon,
        };

        return [
            columnAgreementName
        ]
    };

    render() {

        if(!this._columns) return null;

        let filteredData = this.props.data.filter(x => x.title !== null);

        return (
            <div className="available-table-container">

                {this.state.downloading &&
                    <P>Laddar ner dokument</P>
                }

                <DataTable
                    data={filteredData}
                    defaultSorted={this._defaultSorted}
                    onRowClick={this.onDocumentClick}
                    columns={this._columns}
                />


            </div>
        )
    }
}