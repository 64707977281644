import Cookies from 'universal-cookie'
import {get} from 'services/utils/request/request';
import addHours from 'date-fns/addHours';
import {getApiURL} from 'services/constants';
import {BankIdSession, BankIdStart, LoginResponse } from 'services/authentication/types';
import { sleep } from 'services/utils/helpers';

const cookies = new Cookies();

export const loginQr = async () => {

    let requestUrl = getApiURL() + `/authentication/login/`;

    try {
        let response = await get(requestUrl, true);

        if (response) {
            let data : BankIdStart = await response.json();

            return data;

        }
    } catch (e) {

        console.log(e);
        return null;
        // TODO: Remove when login is in place
        //cookies.set('auth', true, { path: '/', expires: addHours(new Date(), 12), sameSite: 'none' });
    }
};

export const getSessionQr = async (sessionId: string) => {

    let requestUrl = getApiURL() + `/authentication/session/${sessionId}`;

    try {
        let response = await get(requestUrl, true);

        if (response) {
            let data : BankIdSession = await response.json();

            return data;
        }
    } catch (e) {
        console.log(e);
        return null;
    }
};

export const loginSsn = async (identificationNumber: string) => {

    let requestUrl = getApiURL() + `/authentication/login/${identificationNumber}`;

    try {
        let response = await get(requestUrl, true);

        if (response) {
            let data : LoginResponse = await response.json();

            return data;

        }
    } catch (e) {

        console.log(e);

        // TODO: Remove when login is in place
        //cookies.set('auth', true, { path: '/', expires: addHours(new Date(), 12), sameSite: 'none' });
    }
};

export const getLoginStatusSsn = async (sessionId: string) => {

    let requestUrl = getApiURL() + `/authentication/getloginstatus/${sessionId}`;

    try {
        let response = await get(requestUrl, true);

        if (response) {
            let data = await response.json();

            return data;
        }
    } catch (e) {
        console.log(e);
    }
};

export const testLogin = async (identificationNumber: string) => {

    let requestUrl = getApiURL() + `/authentication/testlogin/${identificationNumber}`;

    try {
        let response = await get(requestUrl, true);

        if (response) {
            let data = await response.json();

            return data;
        }
    } catch (e) {
        console.log(e);
    }

};

export const testLoginDelay = async () => {
    await sleep(2000);
    return null;
};

export const setLoginCookies = (token: string) => {
    cookies.set('authToken', token, { path: '/', expires: addHours(new Date(), 12) });
};

export const logout = () => {
    cookies.set('authToken', '');
};


export const isLoggedIn = () => getAuthTokenCookie() !== '';

export const getAuthTokenCookie = () => {
    if(!cookies.get('authToken')) {
        return '';
    }

    return cookies.get('authToken');
};