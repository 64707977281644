import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const PlotActive: React.SFC<IconProps> = (props) => {
    const {height, width} = props;

    return (
        <IconBase viewPortWidth={59} viewPortHeight={68} height={height} width={width}>
            <g>
                <defs>
                    <path id="plot-active-b" d="M1.04 0h29v29h22v31h-51z"/>
                    <filter id="plot-active-a" width="127.5%" height="123.3%" x="-13.7%" y="-8.3%" filterUnits="objectBoundingBox">
                        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
                        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"/>
                        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.247058824 0 0 0 0 0.247058824 0 0 0 0 0.235294118 0 0 0 0.25 0"/>
                    </filter>
                </defs>
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(2.96 2)">
                        <use fill="#000" filter="url(#plot-active-a)" xlinkHref="#plot-active-b"/>
                        <use fill="#FFF" xlinkHref="#plot-active-b"/>
                    </g>
                    <path fill="#785F52" fillRule="nonzero" d="M33.826 30.235V2H3.772v60h51.287V30.235H33.826zM16.303 3.765l15.813 16.323V32H43.7l9.65 9.988v17.647L5.481 10.224v-6.46h10.822zM5.481 32.812l26.593 27.423H14.978l-9.497-9.803v-17.62z"/>
                </g>
            </g>
        </IconBase>
    );
};
