import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const Play: React.SFC<IconProps> = (props) => {
    const {width, height} = props;

    return (
        <IconBase viewPortWidth={15} viewPortHeight={20} height={height} width={width}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-3.000000, 0.000000)" fill="#3F3F3C">
                    <path d="M3.94559551,0.739377873 L17.8169171,9.70650083 C18.0488223,9.85641607 18.1152883,10.1659427 17.9653731,10.3978479 C17.9269514,10.4572828 17.8763499,10.507884 17.8169148,10.5463054 L3.94571342,19.5132424 C3.71380738,19.6631564 3.4042811,19.5966886 3.25436713,19.3647826 C3.20208412,19.2839046 3.17427081,19.1896483 3.17427016,19.0933427 L3.17414994,1.15928277 C3.17414809,0.883140391 3.39800422,0.659281265 3.67414659,0.659279414 C3.77045541,0.659278769 3.86471507,0.687092671 3.94559551,0.739377873 Z" />
                </g>
            </g>
        </IconBase>
    );
};
