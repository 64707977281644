import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const TerracedActive: React.SFC<IconProps> = (props) => {
    const {height, width} = props;

    return (
        <IconBase viewPortWidth={74} viewPortHeight={67} height={height} width={width}>
            <g>
                <defs>
                    <path id="terraced-active-b" d="M19 1l15 14L49 1l18 17-1 1-1-1v42H3V18l-1 1-1-1z"/>
                    <filter id="terraced-active-a" width="121.2%" height="123.7%" x="-10.6%" y="-8.5%" filterUnits="objectBoundingBox">
                        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
                        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"/>
                        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.247058824 0 0 0 0 0.247058824 0 0 0 0 0.235294118 0 0 0 0.25 0"/>
                    </filter>
                </defs>
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(3 1)">
                        <use fill="#000" filter="url(#terraced-active-a)" xlinkHref="#terraced-active-b"/>
                        <use fill="#FFF" xlinkHref="#terraced-active-b"/>
                    </g>
                    <path fill="currentColor" d="M70.191 19.043L51.848 1.522 36.737 15.948 21.634 1.522 3.282 19.043l1.293 1.235 1.047-1.017V61h62.211V19.26l1.075 1.036 1.283-1.253zM24.848 59.261h-6.473V48.165h6.473v11.096zm30.214 0h-6.473V48.165h6.49l-.017 11.096z"/>
                </g>
            </g>
        </IconBase>
    );
};
