import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const Sustainability: React.SFC<IconProps> = (props) => {
    const {width, height} = props;

    return (
        <IconBase viewPortWidth={100} viewPortHeight={100} height={height} width={width}>
            <path fill="none" fillRule="evenodd" stroke="#3F3F3C" strokeWidth="2.449" d="M49.633 85.99c9.106 0 30.589-11.38 30.589-35.125 0-15.83-10.196-32.786-30.589-50.865C29.211 18.08 19 35.034 19 50.865 19 74.61 40.528 85.99 49.633 85.99zm0-85.748v99.516m.244-32.125L78.633 38.5M49.39 67.633L20.633 39.5m29.244-.853L67.633 20.5M49.39 38.647L31.633 20.5"/>
        </IconBase>
    );
};
