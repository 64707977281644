import * as React from 'react';

import lottie from 'lottie-web';
import classnames from 'classnames';

import './style.scss';

interface Props {
    white?: boolean;
}

export class NewtonLoaderSVG extends React.PureComponent<Props> {

    private _loaderRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        if(this._loaderRef.current !== null) {
            lottie.loadAnimation({
                container: this._loaderRef.current, // the dom element that will contain the animation
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: '/static/json/loading_snabbare.json' // the path to the animation json
            });
        }
    }

    render() {
        let loaderClasses = classnames('newton-loader-svg', {
            'newton-loader-svg--white': this.props.white,
        });

        return (
            <div ref={this._loaderRef} className={loaderClasses} />
        )
    }
}
