import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const Minus: React.SFC<IconProps> = (props) => {
    const {width, height} = props;

    return (
        <IconBase fill viewPortWidth={12} viewPortHeight={12} height={height} width={width}>
            <rect x="0" y="5" width="12" height="2"/>
        </IconBase>
    );
};
