import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const SunstudySunOutline: React.SFC<IconProps> = (props) => {
    const {width, height} = props;

    return (
        <IconBase fill viewPortWidth={20} viewPortHeight={20} height={height} width={width}>
            <g fill="#3F3F3C" fillRule="nonzero">
                <path d="M10,14.2424242 C7.65697379,14.2424242 5.75757576,12.3430262 5.75757576,10 C5.75757576,7.65697379 7.65697379,5.75757576 10,5.75757576 C12.3430262,5.75757576 14.2424242,7.65697379 14.2424242,10 C14.2424242,11.1251609 13.7954558,12.2042381 12.999847,12.999847 C12.2042381,13.7954558 11.1251609,14.2424242 10,14.2424242 L10,14.2424242 Z M10,6.96969697 C8.32640985,6.96969697 6.96969697,8.32640985 6.96969697,10 C6.96969697,11.6735902 8.32640985,13.030303 10,13.030303 C11.6735902,13.030303 13.030303,11.6735902 13.030303,10 C13.030303,8.32640985 11.6735902,6.96969697 10,6.96969697 Z"></path>
                <rect x="9.4" y="0" width="1.21212121" height="4.54545455"></rect>
                <rect x="9.4" y="15.4545455" width="1.21212121" height="4.54545455"></rect>
                <rect x="0.00606060606" y="9.39393939" width="4.54545455" height="1.21212121"></rect>
                <rect x="15.4606061" y="9.39393939" width="4.54545455" height="1.21212121"></rect>
                <polygon transform="translate(4.543762, 4.535108) rotate(-45.000000) translate(-4.543762, -4.535108) " points="3.93770124 2.26238117 5.14982245 2.26238117 5.14982245 6.80783572 3.93770124 6.80783572"></polygon>
                <polygon transform="translate(15.469986, 15.465411) rotate(-45.000000) translate(-15.469986, -15.465411) " points="14.8639258 13.1926842 16.076047 13.1926842 16.076047 17.7381387 14.8639258 17.7381387"></polygon>
                <polygon transform="translate(4.543238, 15.464887) rotate(-45.000000) translate(-4.543238, -15.464887) " points="2.27051049 14.8588268 6.81596504 14.8588268 6.81596504 16.070948 2.27051049 16.070948"></polygon>
                <polygon transform="translate(15.470510, 4.535633) rotate(-45.000000) translate(-15.470510, -4.535633) " points="13.1977832 3.92957192 17.7432378 3.92957192 17.7432378 5.14169313 13.1977832 5.14169313"></polygon>
            </g>
        </IconBase>
    );
};
