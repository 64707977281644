import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const Download: React.SFC<IconProps> = (props) => {
    const {height, width} = props;

    return (
        <IconBase viewPortWidth={12} viewPortHeight={16} height={height} width={width} fill>
            <g>
                <rect x="0" y="15" width="12" height="1"/>
                <polygon transform="translate(6.150000, 7.000000) rotate(-90.000000) translate(-6.150000, -7.000000) " points="12.3 6.33333333 2.61716667 6.33333333 5.0635 3.94 4.1 3 0 7 4.1 11 5.0635 10.06 2.61716667 7.66666667 12.3 7.66666667"/>
            </g>
        </IconBase>
    );
};
