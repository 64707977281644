export interface InputData {
    value: any;
    messages: string[];
    validate: string[];
    pristine?: boolean;
}

export interface User {
    token: string;
    name: string;
    email: string;
}

export interface LoginModel {
    personal_identification_number: string;
}

export interface LoginResponse {
    sessionId: string;
}

export interface LoginStatusResponse {
    status: LoginStatus;
    token: string;
}

export enum LoginStatus {
    NOT_LOGGEDIN = 'not-loggedin',
    OK = 'ok',
    NOT_FOUND = 'not-found',
    CANCELED = 'canceled',
}

export enum AuthenticationState {
    IDLE = 'idle',
    POOLING = 'pooling',
    DONE = '',
}

export interface BankIdStart {
    sessionId: string;
    autoStartToken: string;
    qrCode: string;
}

export interface BankIdSession {
    session: string;
    status: BankIdStatus;
    authorized: boolean;
    pnr: string | null;
    firstName: string | null;
    lastName: string | null;
    qrCode: string | null;
    token: string | null;
}

export enum BankIdStatus {
    Ok = 'Ok',
    Pooling = 'Pooling',
    Canceled = 'Canceled',
    Restart = 'Restart',
    Error = 'Error'
}
