import * as React from 'react';
import {IconBase} from 'components/atoms/Icons/_iconBase';
import {IconProps} from 'components/atoms/Icons/types';

export const FlagFi: React.SFC<IconProps> = (props) => {
    const {height, width} = props;

    return (
        <IconBase fill viewPortWidth={30} viewPortHeight={30} height={height} width={width}>
            <g>
                <mask fill="white" id="FiCircle">
                    <path d="M29.8122239,14.9663158 C29.8122239,23.2052632 23.1736343,29.8857895 14.9852383,29.8857895 C6.79684231,29.8857895 0.158252615,23.2052632 0.158252615,14.9663158 C0.158252615,6.72631579 6.79684231,0.0463157895 14.9852383,0.0463157895 C23.1736343,0.0463157895 29.8122239,6.72631579 29.8122239,14.9663158 L29.8122239,14.9663158 Z" />
                </mask>
                <polygon fill="#FFFFFF" mask="url(#FiCircle)" points="0.0652631579 29.9878947 29.9036842 29.9878947 29.9036842 0.150526316 0.0652631579 0.150526316"/>
                <polyline fill="#003580" mask="url(#FiCircle)" points="37.7901754 10.8794737 14.5038596 10.8794737 14.5038596 0.150526316 6.49912281 0.150526316 6.49912281 10.8794737 -6.96719298 10.8794737 -6.96719298 19.2605263 6.49912281 19.2605263 6.49912281 29.9878947 14.5038596 29.9878947 14.5038596 19.2605263 37.7901754 19.2605263 37.7901754 10.8794737"/>
            </g>
        </IconBase>
    );
};
