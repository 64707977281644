import * as React from 'react';

import './style.scss';
import {Container} from 'components/atoms/Container';
import {H1} from '../../atoms/H1';
import {H2} from "../../atoms/H2";
import {H4} from "../../atoms/H4";
import {Button} from "../../atoms/Button";
import {MainGrid} from "../../atoms/MainGrid";
import {DocumentTable} from "../../organisms/tables/DocumentTable";
import {MessageBox} from "../../molecules/MessageBox";
import {getObjectDocuments} from 'services/documents/documents-service';
import {CooperativeObjectModel} from 'services/documents/types';
import {formatSigningLogDate} from "../../../services/utils/date-helper";
import {Loader} from "../../atoms/Loader";
import {ObjectNoteModule} from "../../molecules/ObjectNoteModule";

interface Props {
    computedMatch: any;
}

interface State {
    model: CooperativeObjectModel;
}

export class ObjectPage extends React.PureComponent<Props, State> {

    state = {
        model: null,
    };

    async componentWillMount() {
        //@ts-ignore
        let response: CooperativeObjectModel = await getObjectDocuments(this.props.computedMatch.params.cooperativeId, this.props.computedMatch.params.id);

        this.setState({
            model: response,
        });
    }

    goBack = () => () => {
        window.history.back();
    };

    signAgreement = (url: string) => () => {
        window.open(url, '_self');
    };

    render() {

        if(!this.state.model || this.state.model === null) return <Loader text=""/>;

        return (
            <div className="object-page">
                <Container>
                    <H1>Bostad nr {this.state.model.residenceObjectNumber} - {this.state.model.cooperativeName}</H1>

                    <Button href="/" onClick={this.goBack()}>Tillbaka</Button>

                    <div>
                        {this.state.model.contracts && this.state.model.contracts.map((contract, index) => (

                            <React.Fragment key={`contract-${index}`}>
                                <MainGrid className="block-margin">
                                    <div className="object-page__left">
                                        <React.Fragment key={`signing-${index}`}>
                                            <H2>{contract.contractTypeValue}</H2>


                                            {/* Anteckning */}

                                            <ObjectNoteModule contractId={contract.contractId} objectId={this.state.model.residenceObjectId} note={contract.cooperativeNote} />

                                            {(contract.isSigned != null && !contract.isSigned && contract.signingUrl) &&
                                                    <div className="object-page__agreement">
                                                        <Button primarySticky onClick={this.signAgreement(contract.signingUrl)}>Signera</Button>
                                                    </div>
                                            }
                                        </React.Fragment>
                                    </div>

                                    <div className="object-page__right">

                                        <H2>&nbsp;</H2>

                                        <MessageBox objectId={this.state.model.residenceObjectId} model={contract}/>
                                    </div>

                                </MainGrid>

                                {contract.buyers.length > 0 &&
                                    <div className="object-page__buyer block-margin">
                                    <H4>Köpare</H4>
                                        <table>
                                            <tbody>
                                                {contract.buyers && contract.buyers.map((buyer, index) => (
                                                    <tr key={`buyers-${index}`}>
                                                        <td>{buyer.name}</td>
                                                        <td>(andel {buyer.share})</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                {contract.signingLogs.length > 0 &&
                                    <div className="object-page__log">
                                            <H4>Signeringslogg</H4>
                                            <table>
                                                <tbody>
                                                    {contract.signingLogs.map((logMessage, index) => (
                                                        <tr key={`logmessage-${index}`}>
                                                            <td>{formatSigningLogDate(logMessage.signingDate)}</td>
                                                            {logMessage.signingDate &&
                                                                <td>{logMessage.signerName}, {logMessage.signingPartyType}</td>
                                                            }
                                                            {!logMessage.signingDate &&
                                                                <td><b>{logMessage.signerName}, {logMessage.signingPartyType}</b></td>
                                                            }
                                                            <td>{logMessage.signingStatus}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                    </div>
                                }
                                {contract.documents.length > 0 &&
                                    <div className="object-page__documents">
                                        <H4>Dokument</H4>

                                        <DocumentTable
                                            data={contract.documents}
                                            objectId={this.props.computedMatch.params.id}
                                        />
                                    </div>
                                }
                            </React.Fragment>
                        ))}

                        <Button href="/" onClick={this.goBack()}>Tillbaka</Button>
                    </div>

                </Container>
            </div>
        )
    }
}
