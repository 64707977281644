import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const Appartment: React.SFC<IconProps> = (props) => {
    const {height, width} = props;

    return (
        <IconBase viewPortWidth={27} viewPortHeight={30} height={height} width={width}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-3.000000, 0.000000)" fill="currentColor">
                    <g transform="translate(3.000000, 0.000000)">
                        <path d="M8.82608696,0 L8.82608696,6.52173913 L0.130434783,6.52173913 L0.130434783,30 L26.2173913,30 L26.2173913,0 L8.82608696,0 Z M10.5652174,29.1304348 L7.08695652,29.1304348 L7.08695652,23.5826087 L10.5652174,23.5826087 L10.5652174,29.1304348 Z M16.6521739,29.1304348 L11.4347826,29.1304348 L11.4347826,22.7130435 L6.2173913,22.7130435 L6.2173913,29.1304348 L1,29.1304348 L1,7.39130435 L16.6521739,7.39130435 L16.6521739,29.1304348 Z M17.5217391,23.5782609 L19.2608696,23.5782609 L19.2608696,29.1304348 L17.5217391,29.1304348 L17.5217391,23.5782609 Z M25.3478261,29.1304348 L20.1304348,29.1304348 L20.1304348,22.7130435 L17.5217391,22.7130435 L17.5217391,6.52173913 L9.69565217,6.52173913 L9.69565217,0.869565217 L25.3478261,0.869565217 L25.3478261,29.1304348 Z" />
                    </g>
                </g>
            </g>
        </IconBase>
    );
};
