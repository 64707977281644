import * as React from 'react';

import classNames from 'classnames';

import './style.scss';

interface Props {
    children: any;
    id?:string;
    className?: string;
    fullwidth?: boolean;
    noGap?: boolean;
}

export class MainGrid extends React.PureComponent<Props> {
    render() {

        const gridClassName = classNames('main-grid', this.props.className, {
            'main-grid--full-width': this.props.fullwidth,
            'main-grid--no-gap': this.props.noGap,
        });

        return (
            <div id={this.props.id} className={gridClassName}>
                { this.props.children }
            </div>
        )
    }
}
