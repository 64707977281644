import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const Drag: React.SFC<IconProps> = (props) => {
    const {height, width} = props;

    return (
        <IconBase viewPortWidth={29} viewPortHeight={10} height={height} width={width}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-1245.000000, -901.000000)" fill="#DAD9D3">
                    <g transform="translate(1245.000000, 901.000000)">
                        <path d="M0,0 L29,0 L29,2 L0,2 L0,0 Z M0,4 L29,4 L29,6 L0,6 L0,4 Z M0,8 L29,8 L29,10 L0,10 L0,8 Z" />
                    </g>
                </g>
            </g>
        </IconBase>
    );
};
