import * as React from 'react';

import classnames from 'classnames';

import './style.scss';
import Icons from './Icons';

import {IconProps} from 'components/atoms/Icons/types';

interface Props {
    name: string;
    onClick?: (e: any) => any;
    className?: string;
    block?: boolean;

    up?: boolean;
    down?: boolean;
    left?: boolean;
    right?: boolean;
    flip?: boolean;
    
    thin?: boolean;
    medium?: boolean;
    thic?: boolean;

    sizeXL?: boolean;
    sizeL?: boolean;
    sizeM?: boolean;
    sizeS?: boolean;
    sizeXS?: boolean;
}

export class Icon extends React.PureComponent<Props> {

    getIconFromName = (): React.SFC<IconProps> => {
        return Icons[this.props.name];
    };

    render() {
        let IconComponent = this.getIconFromName();

        if(typeof IconComponent === 'undefined') return null;

        let iconClasses = classnames('icon', `icon--${this.props.name}`, this.props.className, {
            'icon--clickable': this.props.onClick,
            'icon--block': this.props.block,
            'icon--up': this.props.up,
            'icon--down': this.props.down,
            'icon--left': this.props.left,
            'icon--right': this.props.right,
            'icon--thin': this.props.thin,
            'icon--medium': this.props.medium,
            'icon--thic': this.props.thic,
            'icon--flip': this.props.flip,
            'icon--size-xl': this.props.sizeXL,
            'icon--size-l': this.props.sizeL,
            'icon--size-m': this.props.sizeM,
            'icon--size-s': this.props.sizeS,
            'icon--size-xs': this.props.sizeXS,
        });

        return (
            <span className={iconClasses} onClick={this.props.onClick} data-test-id={`icon-${this.props.name}`}>
                <IconComponent />
            </span>
        );
    }

}
