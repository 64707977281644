import * as React from 'react';
import {IconBase} from 'components/atoms/Icons/_iconBase';
import {IconProps} from 'components/atoms/Icons/types';

export const UserLoggedIn: React.SFC<IconProps> = (props) => {
    const {height, width} = props;

    return (
        <IconBase viewPortWidth={20} viewPortHeight={20} height={height} width={width}>
            <g stroke="none" fill="#3F3F3C" fillRule="nonzero">
                <circle cx="9.83823529" cy="5.70294118" r="5.64117647"/>
                <path d="M13.8235294,10.4823529 C11.5590096,12.3820273 8.26835014,12.4189595 5.96176471,10.5705882 C2.34236715,12.1682437 0.00517435521,15.7495564 7.10542736e-15,19.7058824 L7.10542736e-15,20 L20,20 L20,19.7058824 C19.9932777,15.6660146 17.5564408,12.0270048 13.8235294,10.4823529 Z" />
            </g>
        </IconBase>
    );
};
