import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const InputOk: React.SFC<IconProps> = (props) => {
    const {width, height} = props;

    return (
        <IconBase viewPortWidth={22} viewPortHeight={22} height={height} width={width}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(1.000000, 1.000000)">
                    <g>
                        <circle className="fill" stroke="#68A493" cx="10" cy="10" r="10"></circle>
                        <g transform="translate(3.400000, 5.100000)" fill="#68A493">
                            <polygon className="icon" points="4.33333333 7.97857143 1.32166667 5 0.296111111 6.00714286 4.33333333 10 13 1.42857143 11.9816667 0.421428571"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </IconBase>
    );
};
