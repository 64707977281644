import * as React from 'react';

import classnames from 'classnames';

import './style.scss';

import {Icon} from 'components/atoms/Icon';
import {Button} from 'components/atoms/Button';
import {deepClone, isNumber} from 'services/utils/helpers';
import {FoldableTableRow} from "./_foldableRow";

interface Props {
    columns: DataTableColumn[];
    data: any[];
    defaultSorted: { id: string, desc: boolean, sortMethod?: any, }
    foldable?: boolean;
    notSortable?: boolean;
    foldableCount?: number;
    foldableText?: string;
    className?: string;
    currentObjectId?: string;
    draggableRows?: boolean;
    onRowOrderChange?: any;
    editing?: boolean;
    reorderId?: string;
    onRowClick?: (data: any) => (e: any) => any;
    currentItem?: string;
    showAll?: boolean;
    childRowAccessor?: string;
    childRowColumns?: DataTableColumn[];
    onChildRowClick?: (data: any) => (e: any) => any;
}

export interface DataTableColumn {
    name: string;
    nameRenderer?: (name: string) => any;
    nameAffix?: string;
    accessor: string;
    accessorRenderer?: (value: string | string[], originalData?: any) => any;
    accessorAffix?: string;
    sortMethod?: (a: any, b: any) => any;
    width?: number;
    minWidth?: number;
    maxWidth?: number;
    hide?: boolean;
    leftAlign?: boolean;
    notMobile?: boolean;
    secondRow?: boolean;
}

interface State {
    foldCount: number;
    foldOpen: boolean;
    sortKey: string;
    sortDesc: boolean;
    sortMethod: (a: any, b: any) => any | boolean,
}


export class DataTable extends React.PureComponent<Props> {

    static defaultProps = {
        foldableCount: 50,
    };

    defaultSort = (a: any, b: any) => {
        let key = this.state.sortKey;

        let aValue = a[key];
        let bValue = b[key];

        if(isNumber(aValue) && isNumber(bValue)) {
            return aValue === bValue ? 0 : aValue > bValue ? 1 : -1;
        }

        let column = this.props.columns.find(x=> x.accessor === key);

        if (column && column.accessorRenderer){
            aValue = column.accessorRenderer(a[key], a);
            bValue = column.accessorRenderer(b[key], b);
        }

        return aValue === bValue ? 0 : aValue > bValue ? 1 : -1;
    };

    state: State = {
        foldCount: this.props.foldableCount ? this.props.foldableCount : this.props.data.length,
        foldOpen: false,
        sortKey: this.props.defaultSorted.id,
        sortDesc: this.props.defaultSorted.desc,
        sortMethod: this.defaultSort,
    };

    toggleFold = () => {
        this.setState({
            foldCount: (this.state.foldOpen) ? this.props.foldableCount : this.props.data.length,
            foldOpen: !this.state.foldOpen,
        });
    };

    componentDidMount () {
        let sortMehtod = this.props.defaultSorted.sortMethod
            ? this.props.defaultSorted.sortMethod
            : this.props.columns.filter((column) => column.accessor === this.props.defaultSorted.id)[0].sortMethod;

        if(sortMehtod) {
            this.setState({
                sortMethod: sortMehtod,
            })
        }
    }

    getCellStyle = (column: DataTableColumn) => {

        if(column.maxWidth) {
            return {
                maxWidth: column.maxWidth,
                width: column.maxWidth /2,
                flex: `${column.maxWidth} 0 auto`,
            }
        }

        if(column.minWidth) {

            return {
                width: column.minWidth,
                flex: `${column.minWidth} 0 auto`,
            }
        }

        if(column.width) {
            return {
                width: column.width,
                maxWidth: column.width,
                flex: `${column.width} 0 auto`,
            }
        }

        return {
            width: 100,
            flex: `100 0 auto`,
        };
    };

    getPreparedData = () => {

        let dataClone = deepClone(this.props.data);

        let data = [...dataClone.sort(this.state.sortMethod)];

        if(!this.state.sortDesc) {
            data.reverse();
        }

        let object = data.find(x => x.name === this.props.currentObjectId);

        if(!object) {
            // @ts-ignore
            object = data.find(x => this.props.currentItem && x.name === this.props.currentItem.toLowerCase())
        }


        if(this.props.showAll) {
            return data;
        }

        return data.slice(0, this.state.foldCount);


    };

    sortColumn = (column: DataTableColumn) => (e: any) => {
        e.preventDefault();

        if(this.props.notSortable) return;

        this.setState({
            sortMethod: (column.sortMethod) ? column.sortMethod : this.defaultSort,
            sortKey: column.accessor,
            sortDesc: !this.state.sortDesc,
        });
    };


    shouldHaveActiveClass = (data: any) => {
        let active: boolean = false;

        if(this.props.currentItem) {
            active = this.props.currentItem.toString() === data.apartmentNumber.toString();
            return active;
        }

        if(this.props.currentObjectId) {
            active = this.props.currentObjectId.toString() === data.apartmentNumber.toString();
        }

        return active;
    };

    render() {
        let dataTableClasses = classnames('data-table', this.props.className, {
            'data-table--foldable': this.props.foldable,
            'data-table--edit': this.props.editing,
        });

        return (
            <div className={dataTableClasses}>
                <div className="data-table__edge-shade">
                    <div className="data-table__scroll-box">

                        <div className="data-table__header">
                            {this.props.columns.map((column, index) => {
                                if(column.hide) return null;

                                return (
                                    <div className="data-table__cell data-table__header-cell"
                                        key={`${column}-${column.name}-${index}`} style={this.getCellStyle(column)}
                                        onClick={this.sortColumn(column)}>
                                        <span>{(column.nameRenderer ? column.nameRenderer(column.name) : column.name)} {column.nameAffix && column.nameAffix}</span>
                                        {(!this.props.notSortable && column.accessor === this.state.sortKey && this.state.sortDesc) &&
                                        <Icon name="chevron" up/>}
                                        {(!this.props.notSortable && column.accessor === this.state.sortKey && !this.state.sortDesc) &&
                                        <Icon name="chevron" down/>}
                                    </div>
                                );
                            })}

                        </div>

                        <div className="data-table__body">
                            {!this.props.editing && this.getPreparedData().map((data, index) => (
                                <FoldableTableRow
                                    key={`ftr-${index}`}
                                    data={data}
                                    index={index}
                                    childRowAccessor={this.props.childRowAccessor}
                                    childRowColumns={this.props.childRowColumns}
                                    columns={this.props.columns}
                                    getCellStyle={this.getCellStyle}
                                    isInEditMode={false}
                                    onRowClick={this.props.onRowClick && this.props.onRowClick(data)}
                                    rowIsActive={this.shouldHaveActiveClass(data)}
                                    onChildRowClick={this.props.onChildRowClick}
                                />
                            ))}

                        </div>
                    </div>

                </div>

                { this.props.foldable && this.props.foldableCount && this.props.data.length > this.props.foldableCount &&
                    <div className="data-table__controlls">
                        {!this.state.foldOpen &&
                            <Button onClick={this.toggleFold} ghost>
                                {!this.state.foldOpen && <span>{this.props.foldableText ? this.props.foldableText : 'Visa alla'}</span>}
                            </Button>
                        }
                    </div>
                }
            </div>
        )
    }
}

