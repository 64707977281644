import * as React from 'react';

import {
    RouteComponentProps, 
    withRouter
} from 'react-router';
import {isFunction} from 'util';
import classnames from 'classnames';

import './style.scss';
import {Icon} from 'components/atoms/Icon';
import {NewtonLoaderSVG} from 'components/atoms/NewtonLoaderSVG';

interface Props extends RouteComponentProps<any>{
    children: any;
    id?:string;
    href?: string;
    className?: string;
    tabIndex?: number;
    icon?: any;
    iconFirst?: boolean;
    togglable?: boolean;
    ghost?: boolean;
    foldable?: boolean;
    foldableActive?: boolean;
    bright?: boolean;
    light?: boolean;
    active?: boolean;
    primary?: boolean;
    primarySticky?: boolean;
    inactive?: boolean;
    loading?: boolean;
    small?: boolean;
    wide?: boolean;
    attachedEvent?: () => any;
    onClick?: () => any;
    preventDefault?: boolean;
    'data-test-id'?: string;
}

interface State {
    active: boolean;
}

export class ButtonComp extends React.PureComponent<Props> {

    state: State = {
        active: this.props.active ? true : false,
    };

    setActiveState = () => {
        if(this.props.togglable) {
            this.setState({
                active: !this.state.active,
            });
        }
    };

    onClick = (e: any) => {
        if(this.props.preventDefault) e.preventDefault();
        if(this.props.loading) return;

        this.setActiveState();
        (this.props.onClick) && this.props.onClick();

        if(this.props.attachedEvent) {
            this.props.attachedEvent();
        }

        if(this.props.href) {
            this.props.history.push(this.props.href);
        }
    };

    render() {

        let buttonClasses = classnames(this.props.className, {
            'button--ghost': this.props.ghost,
            'button--bright': this.props.bright,
            'button--light': this.props.light,
            'button--active': this.props.active,
            'button--foldable': this.props.foldable,
            'button--filter-active': this.props.foldable && this.props.foldableActive,
            'button--main-cta': this.props.primary,
            'button--main-cta-sticky': this.props.primarySticky,
            'button--has-icon': this.props.icon,
            'button--small': this.props.small,
            'button--inactive': this.props.inactive,
            'button--wide': this.props.wide,
        });


        return (
            <button tabIndex={this.props.tabIndex} id={this.props.id} className={buttonClasses} onClick={this.onClick} disabled={this.props.inactive || this.props.loading} data-test-id={this.props["data-test-id"]}>
                {(this.props.icon && this.props.iconFirst) && this.props.icon}
                <span className="button__content">
                    {
                        isFunction(this.props.children)
                            ? this.props.children(this.state.active)
                            : (
                                <React.Fragment>
                                    {this.props.loading && <NewtonLoaderSVG white/>}
                                    {!this.props.loading && this.props.children } 
                                    { !this.props.loading && this.props.foldable && <Icon name="chevron" down />  }
                                </React.Fragment>
                            )
                    }
                </span>
                {(this.props.icon && !this.props.iconFirst) && this.props.icon}
            </button>
        )
    }

}

export const Button = withRouter(ButtonComp);
