import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const Interest: React.SFC<IconProps> = (props) => {
    const {height, width} = props;

    return (
        <IconBase viewPortWidth={27} viewPortHeight={30} height={height} width={width}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-3.000000, 0.000000)" fill="#3F3F3C" fillRule="nonzero">
                    <g transform="translate(3.000000, 0.000000)">
                        <path d="M0,0 L0,29.8521739 L13.0434783,22.8956522 L26.0391304,29.8521739 L26.0391304,0 L0,0 Z M25.1913043,28.4043478 L13.0695652,21.926087 L0.869565217,28.4086957 L0.869565217,0.869565217 L25.1913043,0.869565217 L25.1913043,28.4043478 Z" />
                        <path d="M8.56521739,18.2608696 L13.073913,15.8913043 L17.5869565,18.2608696 L16.7173913,13.2391304 L20.3652174,9.6826087 L15.3217391,8.95217391 L13.073913,4.36956522 L10.8217391,8.93913043 L5.77826087,9.66956522 L9.42608696,13.226087 L8.56521739,18.2608696 Z M7.64782609,10.2782609 L11.4,9.73043478 L13.073913,6.33478261 L14.7521739,9.73043478 L18.5043478,10.2782609 L15.7913043,12.9217391 L16.4304348,16.6608696 L13.073913,14.8956522 L9.72173913,16.6608696 L10.3608696,12.9217391 L7.64782609,10.2782609 Z" />
                    </g>
                </g>
            </g>
        </IconBase>
    );
};
