import * as React from 'react';

import './style.scss';
import classnames from "classnames";

interface Props {
    text: string;
    white?: boolean;
    small?: boolean;
}

export const Loader: React.FunctionComponent<Props> = ({text, white, small}) => {


    let classes = classnames('loader-container', {
        'loader-white': white,
        'loader-small': small,
    })

    return (
        <div>
            <div className={classes}>
                <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div className="loader-container">{text}</div>
        </div>
    );
};
