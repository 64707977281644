import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const Edit: React.SFC<IconProps> = (props) => {
    const {width, height} = props;

    return (
        <IconBase viewPortWidth={20} viewPortHeight={20} height={height} width={width}>
            <g fill="currentColor">
                <path d="M3.409 16.052H.659l.65.683V14.2v-.358c-.063.162-.128.321-.19.483l.332-.35.909-.957L3.7 11.605 5.33 9.891l1.789-1.883L8.92 6.111l1.676-1.764 1.406-1.48 1.006-1.06c.153-.16.305-.325.46-.484l.011-.011.002-.003.022-.023c.002.019-.143.09-.058.053.017-.007.034-.018.052-.027l-.156.068c.037-.016.071-.025.11-.034l-.173.025c.048-.007.095-.007.143 0-.058-.01-.115-.016-.173-.025.039.006.073.018.11.034l-.156-.068c.024.01.052.036.078.043-.1-.034-.108-.098-.06-.046l.004.005.033.034.142.15.509.535 1.174 1.237.18.189.043.045.006.007c.01.007-.036-.027.022.023-.067-.098-.084-.119-.05-.062.011.019.02.037.026.055l-.065-.164c.016.039.024.075.033.116l-.024-.182c.007.05.007.1 0 .15l.024-.182c-.007.041-.017.078-.033.116l.065-.164c-.015.032-.08.132-.008.039.06-.084.03-.039.01-.02l-.002.002-.013.013-.056.06-.234.246-.886.933-1.326 1.396-1.618 1.703-1.785 1.878-1.804 1.9-1.685 1.773-1.419 1.494-1.025 1.08c-.162.17-.327.338-.487.511l-.022.023c-.24.253-.257.717 0 .966.26.25.662.27.918 0l.462-.488 1.231-1.295 1.768-1.86L9.4 11.388l2.141-2.254 1.98-2.083 1.583-1.667.954-1.004c.056-.06.114-.116.168-.178.277-.305.403-.74.292-1.152-.064-.241-.205-.43-.37-.603L14.35.553c-.066-.07-.133-.143-.2-.211-.446-.449-1.119-.454-1.57-.016-.046.043-.087.09-.13.136l-.874.92-1.527 1.608-1.95 2.051-2.134 2.247-2.09 2.2-1.81 1.906-1.3 1.368c-.184.194-.379.383-.556.585-.137.155-.195.349-.195.554v2.83c0 .369.296.683.649.683h2.75c.339 0 .663-.314.648-.683-.017-.367-.288-.679-.651-.679zM.006 18.508H17.149V19.711H.006z" transform="translate(1)"/>
                <path d="M10.314 3.675l.932.981 1.477 1.555.342.36c.24.253.681.271.917 0 .238-.273.258-.697 0-.965l-.932-.982-1.478-1.555-.341-.36c-.24-.252-.682-.27-.917 0-.238.274-.256.697 0 .966z" transform="translate(1)"/>
            </g>
        </IconBase>
    );
};
