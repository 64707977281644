import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const Fullscreen: React.SFC<IconProps> = (props) => {
    const {height, width} = props;

    return (
        <IconBase viewPortWidth={15} viewPortHeight={15} height={height} width={width}>
            <path fill="#3F3F3C" fillRule="nonzero" d="M.556 5A.556.556 0 0 1 0 4.444V.556C0 .249.249 0 .556 0h3.888a.556.556 0 0 1 0 1.111H1.111v3.333A.556.556 0 0 1 .556 5zM5 14.444a.556.556 0 0 0-.556-.555H1.111v-3.333a.556.556 0 1 0-1.111 0v3.888c0 .307.249.556.556.556h3.888A.556.556 0 0 0 5 14.444zm10-10V.556A.556.556 0 0 0 14.444 0h-3.888a.556.556 0 0 0 0 1.111h3.333v3.333a.556.556 0 1 0 1.111 0zm0 10v-3.888a.556.556 0 0 0-1.111 0v3.333h-3.333a.556.556 0 1 0 0 1.111h3.888a.556.556 0 0 0 .556-.556z"/>
        </IconBase>
    );
};
