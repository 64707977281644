import {getApiURL} from 'services/constants';
import {get, post} from 'services/utils/request/request';
import {getAuthTokenCookie} from 'services/authentication/authentication-service';

const isResponseDataValid = (data: string) => {
    if (data === 'login-again') {
        return false;
    }
    return true;
}

const LoginAgain = () => {
    window.location.href = "/login";
    return;
}

export const getDocuments = async () => {

    let requestUrl = getApiURL() + `/api/cooperative/cooperative-documents`;
    let token = getAuthTokenCookie();

    if(!token) {
        console.error('Missing auth token');
        return LoginAgain();
    }

    try {
        let response = await get(requestUrl, true, token);

        if (response) {
            let data = await response.json();
            if (!isResponseDataValid(data)) return LoginAgain();
            return data;
        }
    } catch (e) {
        console.log(e);
    }

};

export const getObjectDocuments = async (cooperativeId: string, objectId: string) => {
    // TODO: Change when we have real data
    let requestUrl = getApiURL() + `/api/cooperative/cooperative-object-documents/${objectId}`;
    //let requestUrl = "https://jm-brf-api-dev.azurewebsites.net/api/cooperative/cooperative-object-documents/db163d78-e742-e811-813e-e0071b66a091";
    let token = getAuthTokenCookie();

    if(!token) {
        console.error('Missing auth token');
        return LoginAgain();
    }

    try {
        let response = await get(requestUrl, true, token);

        if (response) {
            let data = await response.json();
            if (!isResponseDataValid(data)) return LoginAgain();
            return data;
        }
    } catch (e) {
        console.log(e);
    }
};

export const getObjects = async (cooperativeId: string) => {
    // TODO: Change when we have real data
    let requestUrl = getApiURL() + `/api/cooperative/cooperative-objects/${cooperativeId}`;
    let token = getAuthTokenCookie();

    if(!token) {
        console.error('Missing auth token');
        return LoginAgain();
    }

    try {
        let response = await get(requestUrl, true, token);

        if (response) {
            let data = await response.json();
            if (!isResponseDataValid(data)) return LoginAgain();
            return data;
        }
    } catch (e) {
        console.log(e);
    }
};

export const downloadDocument = async (objectId: string, documentId: string, filename: string) => {
    // TODO: Change when we have real data
    let requestUrl = getApiURL() + `/api/cooperative/cooperative-object-document/${objectId}/${documentId}/${filename}`;
    let token = getAuthTokenCookie();

    if(!token) {
        console.error('Missing auth token');
        return LoginAgain();
    }

    try {
        let response = await get(requestUrl, true, token);        

        if (response) {
            response.blob().then(function(myBlob){
                var objectURL = URL.createObjectURL(myBlob);

                var downloadLink = document.createElement("a");
                downloadLink.href = objectURL;
                downloadLink.download = filename;

                return downloadLink.click();
            });
        }
    } catch (e) {
        console.log(e);
    }
};

export const getSignStatus = async (taskId: string) => {
    // TODO: Change when we have real data
    let requestUrl = getApiURL() + `/api/cooperative/cooperative-objects/sign-status/${taskId}`;
    let token = getAuthTokenCookie();

    if(!token) {
        console.error('Missing auth token');
        return LoginAgain();
    }

    try {
        let response = await get(requestUrl, true, token);

        if (response) {
            let data = await response.json();
            if (!isResponseDataValid(data)) return LoginAgain();
            return data;
        }
    } catch (e) {
        console.log(e);
    }
};

export const sendMessage = async (objectId: string, contractId: string, message: string) => {
    let requestUrl = getApiURL() + `/api/cooperative/send-message/`;
    let token = getAuthTokenCookie();

    if(!token) {
        console.error('Missing auth token');
        return LoginAgain();
    }

    try {
        let response = await post(requestUrl, {
            objectId: objectId,
            contractId: contractId,
            message: message,
        }, true, token);

        if (response) {
            let data = await response.json();
            if (!isResponseDataValid(data)) return LoginAgain();
            return data;
        }
    } catch (e) {
        console.log(e);
    }
};

export const sendCooperativeNote = async (objectId: string, contractId: string, message: string) => {
    let requestUrl = getApiURL() + `/api/cooperative/send-cooperative-note/`;
    let token = getAuthTokenCookie();

    if(!token) {
        console.error('Missing auth token');
        return LoginAgain();
    }

    try {
        let response = await post(requestUrl, {
            objectId: objectId,
            contractId: contractId,
            message: message,
        }, true, token);

        if (response) {
            let data = await response.json();
            if (!isResponseDataValid(data)) return LoginAgain();
            return data;
        }
    } catch (e) {
        console.log(e);
    }
};