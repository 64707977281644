import * as React from 'react';

import swishHorizontal from './assets/bankid.png';

interface Props {
    width?: number;
    height?: number;
}

export const BankID: React.SFC<Props> = ({width, height}) => {
    return (
        <img src={swishHorizontal} width={width} height={height} alt='' />
    );
};
