import * as React from 'react'
import {Route, Switch} from 'react-router';
import {StartPage} from 'components/pages/StartPage';
import {PrivateRoute} from 'components/atoms/PrivateRoute';
import {Header} from 'components/molecules/layout/Header';
import LoginPage from 'components/pages/LoginPage';
import {ObjectPage} from "components/pages/ObjectPage";
import {ArchivePage} from "components/pages/ArchivePage";
import ThankYouPage from "../../components/pages/ThankYouPage";
import SigningCanceledPage from "../../components/pages/SigningCanceledPage";


interface Props {

}

export class App extends React.Component<Props> {


    render() {

        return (
            <main id="main-page" className="desktop">

                <Header />

                <div>
                    <Switch>
                        <PrivateRoute path="/object-page/:cooperativeId/:id" component={ObjectPage} />

                        <PrivateRoute exact path='/' component={StartPage} />
                        <PrivateRoute path="/archive/:cooperativeId" component={ArchivePage} />

                        <Route exact path='/tack-signering'>
                            <ThankYouPage/>
                        </Route>
                        <Route exact path='/avbruten-signering'>
                            <SigningCanceledPage/>
                        </Route>
                        <Route exact path='/login'>
                            <LoginPage />
                        </Route>
                    </Switch>
                </div>
            </main>
        )
    };
}

