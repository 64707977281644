import * as React from 'react';
import {
    getSessionQr,
    isLoggedIn,
    loginQr,
    logout,
    setLoginCookies,
    testLogin,
    testLoginDelay
} from 'services/authentication/authentication-service';
import {RouteComponentProps, withRouter} from 'react-router';
import {
    AuthenticationState,
    BankIdSession,
    BankIdStart,
    BankIdStatus,
    User
} from 'services/authentication/types';
import {IsMobile, getMobileOperatingSystem, sleep} from 'services/utils/helpers';
import {ENV, getCurrentEnv} from 'services/constants';


export interface AuthenticationQrContextType {
    user: User,
    loggedIn: boolean;
    loginStarted: boolean;
    autoStartBankId: boolean;
    authenticationState: AuthenticationState;
    qrCode: string;
    isMobile: boolean;
    setLoggedIn: (source: string, autoStartBankId: boolean) => any;
    setLogout: () => any;
    cancelLogin: () => any;
    errorMessage: string;
}

interface Props extends RouteComponentProps {
    children: any;
}

// @ts-ignore
export const AuthenticationQrContext = React.createContext<AuthenticationQrContextType>();
export const AuthenticationQrConsumer = AuthenticationQrContext.Consumer;

export class AuthenticationQrProviderComp extends React.PureComponent<Props, AuthenticationQrContextType> {

    async componentDidMount() {

        if(window.location.href.indexOf('poolingId') > -1) {
            try {
                let poolingId = window.location.search.replace('?poolingId=', '');
    
                let i = 0;
    
                while (i < 30) {
                    i++;
                    await sleep(1000);
                    let poolingResponse = await getSessionQr(poolingId);
    
                    if(poolingResponse.authorized) {
                        this.handleBankIdSession(poolingResponse);
                        break;
                    }
                }
            } catch (e) {

            }
        }
    }

    cancelLogin = async () => {
        this.setState({
            user: null,
            authenticationState: AuthenticationState.IDLE,
            loggedIn: false,
            loginStarted: false
        })
    };

    getPoolingUrl = (sessionId:string) => {
        return window.location.origin + window.location.pathname + `?poolingId=${sessionId}`;
    }

    getRedirectUrl = (poolingUrl:string) => {

        var redirectUrl = encodeURIComponent(poolingUrl);

        var mobileOS = getMobileOperatingSystem();

        if (mobileOS === "Windows Phone") 
        {
            return redirectUrl;
        } 
        else if (mobileOS === "iOS") 
        {
            if (navigator.userAgent.match('CriOS')) 
            {
                return "googlechromes://";
            } 
            else if (navigator.userAgent.match('FxiOS')) 
            {
                return "firefox://open-url?url=" + redirectUrl;
            } 
            else if (navigator.userAgent.match('GSA')) 
            { 
                return "googleapp://open-url?url=" + redirectUrl;
            } 
            else if (navigator.userAgent.match('EdgiOS')) 
            { 
                return "microsoft-edge-https://";
            } 
            else if (navigator.userAgent.match('FBAN')) 
            {
                return "fb://";
            } 
            else if (navigator.userAgent.match("SKVMob")) 
            {
                return "skvmob://";
            }

            return redirectUrl;
        }

        return "null";
    }

    getBankIdUrl = (autoStartToken:string, redirectUrl:string) => {

        var bankIdUrl = `/?autostarttoken=${autoStartToken}&redirect=${redirectUrl}`;
        var mobileOs = getMobileOperatingSystem();
        
        // @ts-ignore
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        
        if (mobileOs === "iOS" && /GSA/.test(userAgent)) {
            bankIdUrl = "https://app.bankid.com" + bankIdUrl
        } else {
            bankIdUrl =  "bankid://" + bankIdUrl;
        }

        return bankIdUrl;
    }

    setLoggedIn = async (source: string, autoStartBankId: boolean) => {

        let env = getCurrentEnv();

        let useTestLogin = false;
        let isTestingEnvironment = env === ENV.LOCAL || env === ENV.DEV || env === ENV.TEST;

        this.setState({
            loginStarted: true,
            autoStartBankId: autoStartBankId,
        });

        let loginResult : BankIdStart = (env === ENV.PROD || !useTestLogin) ? await loginQr() : await testLoginDelay();

        if(isTestingEnvironment || !useTestLogin || (loginResult !== null && loginResult.sessionId)) {

            if(this.state.isMobile && autoStartBankId) {

                let poolingUrl = this.getPoolingUrl(loginResult.sessionId);
                window.history.replaceState({}, document.title, poolingUrl);

                let redirectUrl = this.getRedirectUrl(poolingUrl);
                window.location.href = this.getBankIdUrl(loginResult.autoStartToken, redirectUrl);
                
                //return;
            }

            let statusResponse: BankIdSession;

            this.setState({
                authenticationState: AuthenticationState.POOLING,
                qrCode: loginResult.qrCode
            }, async () => {

                let i = 0;

                while(i < 300) {
                    i++;

                    try{
                        await sleep(2000);

                        statusResponse = isTestingEnvironment && useTestLogin ? await testLogin("") : await getSessionQr(loginResult.sessionId);
    
                        this.setState({
                            qrCode: statusResponse.qrCode
                        });
    
                        if(statusResponse.authorized) {
                            break;
                        }
                    }
                    catch{
                        console.log("retry");
                    }

                }

                this.handleBankIdSession(statusResponse);
            });
        }
    };

    handleBankIdSession = (bankIdSession:BankIdSession) => {
        if(bankIdSession.status === BankIdStatus.Ok && bankIdSession.token) {

            setLoginCookies(bankIdSession.token);

            this.setState({
                user: null,
                loggedIn: true,
                authenticationState: AuthenticationState.IDLE,
                loginStarted: false,
            }, () => {
                this.props.history.push('/');
            });
        }

        if(bankIdSession.status === BankIdStatus.Error || !bankIdSession.token) {
            this.setState({
                authenticationState: AuthenticationState.IDLE,
                loggedIn: false,
                loginStarted: false,
                errorMessage: 'Ingen användare'
            })
        }
    }

    setLogout = () => {
        this.setState({
            user: null,
            loggedIn: false,
        }, () => {
            logout();
        });
    };

    state = {
        user: null,
        loginStarted: false,
        autoStartBankId: false,
        loggedIn: isLoggedIn(),
        setLoggedIn:  this.setLoggedIn,
        setLogout: this.setLogout,
        cancelLogin: this.cancelLogin,
        errorMessage: '',
        authenticationState: AuthenticationState.IDLE,
        qrCode: null,
        isMobile: IsMobile(),
    };

    render() {

        return (
            <AuthenticationQrContext.Provider value={this.state}>
                {this.props.children}
            </AuthenticationQrContext.Provider>
        );
    }

}

export const AuthenticationQrProvider = withRouter(AuthenticationQrProviderComp);
