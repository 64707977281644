import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';

interface Props extends IconProps {
    viewPortHeight: number;
    viewPortWidth: number;
    fill?: boolean;
    children: JSX.Element
}

export const IconBase: React.SFC<Props> = (props) => {
    const {children, fill, height, width, viewPortHeight, viewPortWidth} = props;

    return (
        <svg
            style={{
                width: width,
                height: height,
                fill: fill ? 'currentColor' : 'none',
                stroke: fill ? 'none' : 'currentColor',
                strokeWidth: 1,
            }}
            xmlns="http://www.w3.org/2000/svg"
            width={`${viewPortWidth}px`}
            height={`${viewPortHeight}px`}
            viewBox={`0 0 ${viewPortWidth} ${viewPortHeight}`}
            className="icon__svg"
        >
            {children}
        </svg>
    );
};
