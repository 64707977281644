import * as React from 'react';
import {IconBase} from 'components/atoms/Icons/_iconBase';
import {IconProps} from 'components/atoms/Icons/types';

export const FlagSe: React.SFC<IconProps> = (props) => {
    const {height, width} = props;

    return (
        <IconBase fill viewPortWidth={30} viewPortHeight={30} height={height} width={width}>
            <g>
                <mask fill="white" id="SeCircle">
                    <path d="M29.8122239,14.9663158 C29.8122239,23.2052632 23.1736343,29.8857895 14.9852383,29.8857895 C6.79684231,29.8857895 0.158252615,23.2052632 0.158252615,14.9663158 C0.158252615,6.72631579 6.79684231,0.0463157895 14.9852383,0.0463157895 C23.1736343,0.0463157895 29.8122239,6.72631579 29.8122239,14.9663158 L29.8122239,14.9663158 Z" />
                </mask>
                <polygon fill="#005091" mask="url(#SeCircle)" points="0.158252615 29.8847368 29.8117009 29.8847368 29.8117009 0.0473684211 0.158252615 0.0473684211"/>
                <polyline fill="#FFCD00" mask="url(#SeCircle)" points="36.5597637 11.9694737 12.824661 11.9694737 12.824661 0.0473684211 6.94711352 0.0473684211 6.94711352 11.9694737 -7.92014723 11.9694737 -7.92014723 17.9647368 6.94711352 17.9647368 6.94711352 29.8852632 12.824661 29.8852632 12.824661 17.9647368 36.5597637 17.9647368 36.5597637 11.9694737"/>
            </g>
        </IconBase>
    );
};
