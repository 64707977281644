import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const SunstudySun: React.SFC<IconProps> = (props) => {
    const {width, height} = props;

    return (
        <IconBase fill viewPortWidth={20} viewPortHeight={20} height={height} width={width}>
            <g fill="#3F3F3C" fillRule="evenodd">
                <g transform="translate(0.006061, 0.000000)">
                    <path d="M9.99393939,14.2424242 C7.65091318,14.2424242 5.75151515,12.3430262 5.75151515,10 C5.75151515,7.65697379 7.65091318,5.75757576 9.99393939,5.75757576 C12.3369656,5.75757576 14.2363636,7.65697379 14.2363636,10 C14.2363636,11.1251609 13.7893952,12.2042381 12.9937863,12.999847 C12.1981775,13.7954558 11.1191003,14.2424242 9.99393939,14.2424242 Z M9.39393939,-4.17443857e-14 L10.6060606,-4.17443857e-14 L10.6060606,4.54545455 L9.39393939,4.54545455 L9.39393939,-4.17443857e-14 Z M9.39393939,15.4545455 L10.6060606,15.4545455 L10.6060606,20 L9.39393939,20 L9.39393939,15.4545455 Z M4.13002965e-13,9.39393939 L4.54545455,9.39393939 L4.54545455,10.6060606 L4.13002965e-13,10.6060606 L4.13002965e-13,9.39393939 Z M15.4545455,9.39393939 L20,9.39393939 L20,10.6060606 L15.4545455,10.6060606 L15.4545455,9.39393939 Z M2.50209081,3.35659714 L3.35918994,2.49949801 L6.57331167,5.71361975 L5.71621254,6.57071887 L2.50209081,3.35659714 Z M13.4283154,14.2869002 L14.2854145,13.429801 L17.4995362,16.6439228 L16.6424371,17.5010219 L13.4283154,14.2869002 Z M2.50156673,16.6433987 L5.71568846,13.429277 L6.57278759,14.2863761 L3.35866586,17.5004978 L2.50156673,16.6433987 Z M13.4288395,5.71414383 L16.6429612,2.5000221 L17.5000603,3.35712122 L14.2859386,6.57124296 L13.4288395,5.71414383 Z"></path>
                </g>
            </g>
        </IconBase>
    );
};
