import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const SavedSearchInactive: React.SFC<IconProps> = (props) => {
    const {height, width} = props;

    return (
        <IconBase viewPortWidth={11} viewPortHeight={17} height={height} width={width}>
            <path fill="none" fillRule="evenodd" stroke="#3F3F3C" d="M1 1h8.75v15l-4.375-4.02L1 16z"/>
        </IconBase>
    );
};
