import * as React from 'react';

import './style.scss';
import {Icon} from 'components/atoms/Icon';
import {AuthenticationState} from 'services/authentication/types';
import {Button} from 'components/atoms/Button';
import {RouteComponentProps, withRouter} from 'react-router';
import {AuthenticationQrConsumer, AuthenticationQrContextType} from "services/authentication/authentication-qr-context";
import {P} from 'components/atoms/P';
import {Message} from "../../atoms/Message";
import { A } from 'components/atoms/A';
import { Loader } from 'components/atoms/Loader';

interface Props extends RouteComponentProps {
}

interface State {
    startType: boolean;
}


export class BankIDQrFormComp extends React.PureComponent<Props, State> {

    state = {
        startType : false
    };

    onStart = (callback: (source: string, autoStartBankId: boolean) => any, source: string, isMobile: boolean) => () => {
        if (!isMobile) 
            callback(source, false);
        else {
            this.setState({
                startType: true
            });
        }
    };

    onOtherDevice = (callback: (source: string, autoStartBankId: boolean) => any, source: string, autoStartBankId: boolean) => () => {
        callback(source, autoStartBankId);
    }

    onCancelLogin = (callback: () => any) => (event:any) => {
        event.preventDefault();

        callback();

        this.setState({
            startType: false
        });
    };

    render() {
        return (
            <AuthenticationQrConsumer>
                {(authQrContext: AuthenticationQrContextType) => (
                    <div className="bank-id-login-form"> 
                    
                        {(authQrContext.authenticationState === AuthenticationState.IDLE && !this.state.startType) &&
                            <>
                                <Icon name="bank-id" />

                                {authQrContext.errorMessage &&
                                    <div className="form-row">
                                        <div className="form-column">
                                            <Message error>
                                                {authQrContext.errorMessage}
                                            </Message>
                                        </div>
                                    </div>
                                }

                                <div className="form-row">
                                    <div className="form-column">
                                        <Button onClick={this.onStart(authQrContext.setLoggedIn, "desktop", authQrContext.isMobile)} primary inactive={authQrContext.loginStarted}>Logga in</Button>
                                    </div>
                                </div>

                            </>
                        }

                        {false && authQrContext.loginStarted && <Loader text=""/>}

                        {(authQrContext.authenticationState === AuthenticationState.IDLE && this.state.startType) &&
                            <div className="bank-id-sign-modal__buttons">
                                <Icon name="bank-id" />

                                <Button primary onClick={this.onOtherDevice(authQrContext.setLoggedIn, "same device", true)}>
                                    {authQrContext.isMobile ? "Öppna BankID" : "BankID på samma enhet"}
                                </Button>

                                {authQrContext.isMobile &&
                                    <Button ghost onClick={this.onOtherDevice(authQrContext.setLoggedIn, "other device", false)}>
                                        Använd BankID på en annan enhet
                                    </Button>
                                }
                            </div>
                        }

                        {authQrContext.authenticationState === AuthenticationState.POOLING && !authQrContext.autoStartBankId &&
                            <div className="bank-id-login-form__pooling">

                                <Icon name="bank-id" sizeXL />

                                {authQrContext.qrCode &&
                                    <div className="bank-id-login-form--qr-code" dangerouslySetInnerHTML={{__html: atob(authQrContext.qrCode)}}></div>
                                }

                                {authQrContext.qrCode &&
                                    <>
                                        <P className="bank-id-sign-modal__description">
                                            Starta BankID-appen i din mobil och tryck på Skanna QR-kod. Rikta kameran mot QR-koden här ovanför.
                                        </P>

                                        <div className="bank-id-sign-modal__cancel">
                                            <A href={window.location.href} onClick={this.onCancelLogin(authQrContext.cancelLogin)} className="bank-id-sign-modal__link">
                                                Avbryt inloggning
                                            </A>
                                        </div>
                                    </>
                                }
                            </div>
                        }

                    </div>
                )}
            </AuthenticationQrConsumer>
        )
    }
}

export const BankIDQrForm = withRouter(BankIDQrFormComp);
