import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const Linkedin: React.SFC<IconProps> = (props) => {
    const {width, height} = props;

    return (
        <IconBase fill viewPortWidth={60} viewPortHeight={60} height={height} width={width}>
            <g>
                <path d="M0,30 C0,13.4314567 13.4314567,0 30,0 C46.5685433,0 60,13.4314567 60,30 C60,46.5685433 46.5685433,60 30,60 C13.4314567,60 0,46.5685433 0,30 Z" fill="#0077B5"></path>
                <path d="M21.5279891,24.5771026 L21.5279891,45.5166855 L14.8761013,45.5166855 L14.8761013,24.5771026 L21.5279891,24.5771026 Z M21.9660992,18.0996449 C21.9660992,20.1102307 20.5215845,21.7190328 18.202178,21.7190328 L18.1596689,21.7190328 C15.9264763,21.7190328 14.4827586,20.1102307 14.4827586,18.0996449 C14.4827586,16.0465321 15.9704467,14.4827586 18.2460156,14.4827586 C20.5215845,14.4827586 21.9229259,16.0465321 21.9660992,18.0996449 Z M45.5172414,33.5105686 L45.5169757,45.5166855 L38.8653535,45.5166855 L38.8653535,34.3139968 C38.8653535,31.5001218 37.9027865,29.5800104 35.494775,29.5800104 C33.6570505,29.5800104 32.5624394,30.8744457 32.0816873,32.1246862 C31.9058056,32.5727493 31.8626322,33.1970356 31.8626322,33.8228508 L31.8626322,45.5172414 L25.2099473,45.5172414 C25.2099473,45.5172414 25.2976225,26.5425207 25.2099473,24.5776586 L31.8626322,24.5776586 L31.8626322,27.5435748 C32.7454945,26.1178059 34.3267027,24.0854007 37.8584176,24.0854007 C42.2356664,24.0854007 45.5172414,27.0788345 45.5172414,33.5105686 Z" fill="#FFFFFF"></path>
            </g>
        </IconBase>
    );
};
