import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const Check: React.SFC<IconProps> = (props) => {
    const {width, height} = props;

    return (
        <IconBase viewPortWidth={18} viewPortHeight={14} height={height} width={width}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g>
                    <polygon points="5.59 10.58 1.42 6.41 0 7.82 5.59 13.41 17.59 1.41 16.18 0"/>
                </g>
            </g>
        </IconBase>
    );
};
