export const get = async (url: string, credentials: boolean = false, token?: string) => {
    try {
        return await fetch(url, {
            method: 'GET',
            credentials: ( credentials) ? 'include' : 'omit',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
    } catch(e) {
        console.error(`Error in fetch when trying to GET: ${url}, ${e}`);
        return false;
    }
};

export const post = async (url: string, params: any, credentials: boolean = false, token?: string) => {
    try {
        return await fetch(url, {
            method: 'POST',
            body: JSON.stringify(params),
            credentials: ( credentials) ? 'include' : 'omit',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
    } catch(e) {
        //console.error(`Error in fetch when trying to POST: ${url}, with parameters: ${JSON.stringify(params)}, ${e}`);
        const error: any = new Error(`Error in fetch when trying to POST: ${url}, with parameters: ${JSON.stringify(params)}, ${e}`);
        throw error;
    }
};

export const deleteMethod = async (url: string, params: any, credentials: boolean = false) => {
    try {
        return await fetch(url, {
            method: 'DELETE',
            body: JSON.stringify(params),
            credentials: ( credentials) ? 'include' : 'omit',
            headers: {
                'content-type': 'application/json'
            },
        });
    } catch(e) {
        console.error(`Error in fetch when trying to DELETE: ${url}, with parameters: ${JSON.stringify(params)}, ${e}`);
        return false;
    }
};

