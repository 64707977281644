import * as React from 'react';

import {Redirect, Route, RouteProps} from 'react-router';
import {DocumentsContextType, DocumentsProvider} from 'services/documents/documents-context';
import {Loader} from "../Loader";
import { AuthenticationQrConsumer, AuthenticationQrContextType } from 'services/authentication/authentication-qr-context';

interface Props extends RouteProps {

}

export class PrivateRoute extends React.PureComponent<Props> {

    componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
        setTimeout(() => window.scrollTo(0, 0), 200);
    }

    render() {

        let Page: any = this.props.component;


        return (
            <AuthenticationQrConsumer>
                {(value: AuthenticationQrContextType) => (
                        <Route render={() => (
                            value.loggedIn ? (
                                <DocumentsProvider>
                                    {(documentProvider: DocumentsContextType) => {
                                        if(documentProvider.loading) return <Loader text="" />;
                                        return (
                                            <Page {...this.props} />
                                        )
                                    }}
                                </DocumentsProvider>
                            ) : <Redirect to={{pathname:'/login', state: {from: this.props.location}}} />
                        )}/>
                    )
                }
            </AuthenticationQrConsumer>

        )
    }
}