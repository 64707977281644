import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const Delete: React.SFC<IconProps> = (props) => {
    const {height, width} = props;

    return (
        <IconBase viewPortWidth={20} viewPortHeight={20} height={height} width={width}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-1183.000000, -896.000000)" fill="#CF4646" fillRule="nonzero">
                    <path d="M1193,896 C1187.48,896 1183,900.48 1183,906 C1183,911.52 1187.48,916 1193,916 C1198.52,916 1203,911.52 1203,906 C1203,900.48 1198.52,896 1193,896 Z M1198,907 L1188,907 L1188,905 L1198,905 L1198,907 Z" />
                </g>
            </g>
        </IconBase>
    );
};
