import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const PswShow: React.SFC<IconProps> = (props) => {
    const {width, height} = props;

    return (
        <IconBase viewPortWidth={20} viewPortHeight={13} height={height} width={width}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(0.000000, -4.000000)" fill="#3F3F3C">
                    <path d="M10,14.2777778 C7.85222597,14.2777778 6.11111111,12.5366629 6.11111111,10.3888889 C6.11111111,8.24111486 7.85222597,6.5 10,6.5 C12.147774,6.5 13.8888889,8.24111486 13.8888889,10.3888889 C13.8888889,11.4202863 13.4791678,12.4094405 12.7498597,13.1387486 C12.0205516,13.8680567 11.0313975,14.2777778 10,14.2777778 Z M10,7.61111111 C8.46587569,7.61111111 7.22222222,8.85476458 7.22222222,10.3888889 C7.22222222,11.9230132 8.46587569,13.1666667 10,13.1666667 C11.5341243,13.1666667 12.7777778,11.9230132 12.7777778,10.3888889 C12.7777778,8.85476458 11.5341243,7.61111111 10,7.61111111 Z M10,16.7083333 C5.83168775,16.7031826 2.01948377,14.3574586 0.136111111,10.6388889 L0,10.3888889 L0.130555556,10.1361111 C2.01392572,6.42048229 5.82597576,4.07893492 9.99166667,4.07893492 C14.1573576,4.07893492 17.9694076,6.42048229 19.8527778,10.1361111 L20,10.3888889 L19.8694444,10.6416667 C17.9888297,14.3650191 14.1713373,16.7116004 10,16.7083333 Z M1.25833333,10.3888889 C2.99351333,13.60028 6.34981067,15.6016995 10,15.6016995 C13.6501893,15.6016995 17.0064867,13.60028 18.7416667,10.3888889 C17.0026887,7.1823526 13.647728,5.1852502 10,5.1852502 C6.35227204,5.1852502 2.99731129,7.1823526 1.25833333,10.3888889 Z"></path>
                </g>
            </g>
        </IconBase>
    );
};
