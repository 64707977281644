import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const PaymentVisa
: React.SFC<IconProps> = (props) => {
    const {width, height} = props;

    return (
        <IconBase fill viewPortWidth={216} viewPortHeight={138} height={height} width={width}>
            <g>
                <polygon fill="#FFFFFE" points="0 137.94 216 137.94 216 0.79 0 0.79"></polygon>
                <polygon fill="#F1AD2B" points="3.597 134.425 212.399 134.425 212.399 115.705 3.597 115.705"></polygon>
                <polygon fill="#182E66" points="3.597 23.109 212.399 23.109 212.399 4.389 3.597 4.389"></polygon>
                <path fill="#182E66" d="M106.197,43.733 L95.191,95.188 L81.879,95.188 L92.886,43.733 L106.197,43.733 Z M162.201,76.959 L169.209,57.635 L173.24,76.959 L162.201,76.959 Z M177.061,95.188 L189.369,95.188 L178.615,43.733 L167.261,43.733 C164.701,43.733 162.545,45.217 161.59,47.505 L141.615,95.188 L155.596,95.188 L158.371,87.504 L175.447,87.504 L177.061,95.188 Z M142.309,78.39 C142.367,64.811 123.537,64.058 123.662,57.99 C123.703,56.146 125.461,54.183 129.307,53.681 C131.213,53.435 136.473,53.236 142.436,55.983 L144.768,45.067 C141.564,43.909 137.443,42.793 132.316,42.793 C119.156,42.793 109.898,49.783 109.824,59.801 C109.74,67.209 116.438,71.34 121.473,73.807 C126.664,76.328 128.404,77.946 128.379,80.199 C128.343,83.651 124.238,85.18 120.418,85.237 C113.721,85.342 109.84,83.428 106.746,81.985 L104.33,93.266 C107.445,94.691 113.186,95.933 119.129,95.996 C133.119,95.996 142.268,89.088 142.309,78.39 L142.309,78.39 Z M87.176,43.733 L65.607,95.188 L51.537,95.188 L40.922,54.123 C40.279,51.599 39.718,50.67 37.761,49.604 C34.56,47.864 29.275,46.238 24.629,45.227 L24.943,43.733 L47.595,43.733 C50.481,43.733 53.076,45.653 53.736,48.977 L59.343,78.754 L73.191,43.733 L87.176,43.733 L87.176,43.733 Z"></path>
            </g>
        </IconBase>
    );
};
