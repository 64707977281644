import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const Youtube: React.SFC<IconProps> = (props) => {
    const {width, height} = props;

    return (
        <IconBase fill viewPortWidth={60} viewPortHeight={60} height={height} width={width}>
            <g>
                <path d="M0,30 C0,13.4314567 13.4314567,0 30,0 C46.5685433,0 60,13.4314567 60,30 C60,46.5685433 46.5685433,60 30,60 C13.4314567,60 0,46.5685433 0,30 Z" fill="#FF0000"></path>
                <path d="M45.3312727,22.5915242 C44.9632727,21.1777661 43.8789091,20.0644919 42.502,19.6866613 C40.0067273,19 30,19 30,19 C30,19 19.9932727,19 17.4978182,19.6866613 C16.1209091,20.0644919 15.0365455,21.1777661 14.6685455,22.5915242 C14,25.1537984 14,30.5 14,30.5 C14,30.5 14,35.8460161 14.6685455,38.4084758 C15.0365455,39.8222339 16.1209091,40.9355081 17.4978182,41.3135242 C19.9932727,42 30,42 30,42 C30,42 40.0067273,42 42.502,41.3135242 C43.8789091,40.9355081 44.9632727,39.8222339 45.3312727,38.4084758 C46,35.8460161 46,30.5 46,30.5 C46,30.5 46,25.1537984 45.3312727,22.5915242" fill="#FFFFFF"></path>
                <polygon fill="#FF0000" points="27 36 27 26 35 31.0001911"></polygon>
            </g>
        </IconBase>
    );
};
