import * as React from 'react';

import {IconProps} from 'components/atoms/Icons/types';

import {Appartment} from 'components/atoms/Icons/Appartment';
import {AppartmentActive} from 'components/atoms/Icons/AppartmentActive';
import {ArrowLink} from 'components/atoms/Icons/ArrowLink';
import {BankID} from 'components/atoms/Icons/BankId';
import {BankIDWhite} from 'components/atoms/Icons/BankIDWhite';
import {Check} from 'components/atoms/Icons/Check';
import {Chevron} from 'components/atoms/Icons/Chevron';
import {Contract} from 'components/atoms/Icons/Contract';
import {Delete} from 'components/atoms/Icons/Delete';
import {DocumentIcon} from 'components/atoms/Icons/Document';
import {Download} from 'components/atoms/Icons/Download';
import {Drag} from 'components/atoms/Icons/Drag';
import {ExternalLink} from 'components/atoms/Icons/ExternalLink';
import {Facebook} from 'components/atoms/Icons/Facebook';
import {Favorite} from 'components/atoms/Icons/Favorite';
import {FavoriteFilled} from 'components/atoms/Icons/FavoriteFilled';
import {FlagFi} from 'components/atoms/Icons/FlagFi';
import {FlagNo} from 'components/atoms/Icons/FlagNo';
import {FlagSe} from 'components/atoms/Icons/FlagSe';
import {Fullscreen} from 'components/atoms/Icons/Fullscreen';
import {Hamburger} from 'components/atoms/Icons/Hamburger';
import {House} from 'components/atoms/Icons/House';
import {HouseActive} from 'components/atoms/Icons/HouseActive';
import {InputError} from 'components/atoms/Icons/InputError';
import {InputOk} from 'components/atoms/Icons/InputOk';
import {Instagram} from 'components/atoms/Icons/Instagram';
import {Interest} from 'components/atoms/Icons/Interest';
import {KopAnmalan} from 'components/atoms/Icons/KopAnmalan';
import {Linkedin} from 'components/atoms/Icons/Linkedin';
import {MapMobile} from 'components/atoms/Icons/MapMobile';
import {Minus} from 'components/atoms/Icons/Minus';
import {NetsBankID} from 'components/atoms/Icons/NetsBankId';
import {LookingGlas} from 'components/atoms/Icons/LookingGlas';
import {Pause} from 'components/atoms/Icons/Pause';
import {PaymentMastercard} from 'components/atoms/Icons/PaymentMastercard';
import {PaymentSwish} from 'components/atoms/Icons/PaymentSwish';
import {PaymentSwishLarge} from 'components/atoms/Icons/PaymentSwishLarge';
import {PaymentVipps} from 'components/atoms/Icons/PaymentVipps';
import {PaymentVisa} from 'components/atoms/Icons/PaymentVisa';
import {Play} from 'components/atoms/Icons/Play';
import {Plot} from 'components/atoms/Icons/Plot';
import {PlotActive} from 'components/atoms/Icons/PlotActive';
import {Plus} from 'components/atoms/Icons/Plus';
import {PswHide} from 'components/atoms/Icons/PswHide';
import {PswShow} from 'components/atoms/Icons/PswShow';
import {Showing} from 'components/atoms/Icons/Showing';
import {SunStudyControl} from 'components/atoms/Icons/SunStudyControl';
import {SunstudyHalfSun} from 'components/atoms/Icons/SunstudyHalfSun';
import {SunstudyMoon} from 'components/atoms/Icons/SunstudyMoon';
import {SunstudyMoonOutline} from 'components/atoms/Icons/SunstudyMoonOutline';
import {SunstudySun} from 'components/atoms/Icons/SunstudySun';
import {SunstudySunOutline} from 'components/atoms/Icons/SunstudySunOutline';
import {SwanEcoLabel} from 'components/atoms/Icons/SwanEcoLabel';
import {Terraced} from 'components/atoms/Icons/Terraced';
import {TerracedActive} from 'components/atoms/Icons/TerracedActive';
import {TryggBRF} from 'components/atoms/Icons/TryggBRF';
import {Umbrella} from 'components/atoms/Icons/Umbrella';
import {User} from 'components/atoms/Icons/User';
import {UserLoggedIn} from 'components/atoms/Icons/UserLoggedIn';
import {X} from 'components/atoms/Icons/X';
import {Youtube} from 'components/atoms/Icons/Youtube';
import {PDF} from 'components/atoms/Icons/PDF';
import {SavedSearchInactive} from 'components/atoms/Icons/SavedSearchInactive';
import {SavedSearchActive} from 'components/atoms/Icons/SavedSearchActive';
import {Sustainability} from 'components/atoms/Icons/Sustainability';
import {Edit} from "../Icons/Edit";

const Icons: { [key: string]: React.SFC<IconProps> } = {
    'appartment': Appartment,
    'appartment-active': AppartmentActive,
    'arrow-link': ArrowLink,
    'bank-id': BankID,
    'bank-id-white': BankIDWhite,
    'check': Check,
    'chevron': Chevron,
    'contract': Contract,
    'delete': Delete,
    'document': DocumentIcon,
    'download': Download,
    'drag': Drag,
    'externalLink': ExternalLink,
    'edit': Edit,
    'facebook': Facebook,
    'favorite': Favorite,
    'favorite-filled': FavoriteFilled,
    'flag-fi': FlagFi,
    'flag-no': FlagNo,
    'flag-se': FlagSe,
    'fullscreen': Fullscreen,
    'half-sun': SunstudyHalfSun,
    'hamburger': Hamburger,
    'house': House,
    'house-active': HouseActive,
    'input-error': InputError,
    'input-ok': InputOk,
    'instagram': Instagram,
    'interest': Interest,
    'kop-anmalan': KopAnmalan,
    'linkedin': Linkedin,
    'looking-glas': LookingGlas,
    'map-mobile': MapMobile,
    'minus': Minus,
    'moon': SunstudyMoon,
    'moon-outline': SunstudyMoonOutline,
    'nets-bank-id': NetsBankID,
    'password-hide': PswHide,
    'password-show': PswShow,
    'pause': Pause,
    'payment-mastercard': PaymentMastercard,
    'payment-swish': PaymentSwish,
    'payment-swish-large': PaymentSwishLarge,
    'payment-vipps': PaymentVipps,
    'payment-visa': PaymentVisa,
    'pdf': PDF,
    'play': Play,
    'plot': Plot,
    'plot-active': PlotActive,
    'plus': Plus,
    'saved-search-inactive': SavedSearchInactive,
    'saved-search-active': SavedSearchActive,
    'showing': Showing,
    'sun': SunstudySun,
    'sun-outline': SunstudySunOutline,
    'sun-study-control': SunStudyControl,
    'sustainability': Sustainability,
    'swan-eco-label': SwanEcoLabel,
    'terraced': Terraced,
    'terraced-active': TerracedActive,
    'trygg-brf': TryggBRF,
    'umbrella': Umbrella,
    'user': User,
    'user-logged-in': UserLoggedIn,
    'x': X,
    'youtube': Youtube,
};

export default Icons;
