import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const DocumentIcon: React.SFC<IconProps> = (props) => {
    const {height, width} = props;

    return (
        <IconBase viewPortWidth={18} viewPortHeight={20} height={height} width={width}>
            <g stroke="none" fill="currentColor">
                <path d="M17.7142857,20 L0,20 L0,0 L13.2257143,0 L17.7142857,4.46857143 L17.7142857,20 Z M1.14285714,18.8571429 L16.5714286,18.8571429 L16.5714286,4.94285714 L12.7514286,1.14285714 L1.14285714,1.14285714 L1.14285714,18.8571429 Z"></path>
                <rect x="4.30857143" y="6.84571429" width="9.12571429" height="1.14285714"></rect>
                <rect x="4.30857143" y="13.1542857" width="9.12571429" height="1.14285714"></rect>
                <rect x="4.30857143" y="10" width="9.12571429" height="1.14285714"></rect>
            </g>
        </IconBase>
    );
};
