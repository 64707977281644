import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const Contract: React.SFC<IconProps> = (props) => {
    const {height, width} = props;

    return (
        <IconBase viewPortWidth={25} viewPortHeight={30} height={height} width={width}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-4.000000, 0.000000)" fill="#3F3F3C" fillRule="nonzero">
                    <g transform="translate(4.000000, 0.000000)">
                        <rect x="5.78630137" y="9.84657534" width="13.1260274" height="1"/>
                        <rect x="5.78630137" y="14.3835616" width="13.1260274" height="1"/>
                        <path d="M24.6575342,6.1890411 L18.439726,0 L0,0 L0,27.9452055 L13.0684932,27.9452055 L13.0684932,30 L15.6328767,28.1383562 L18.1849315,30 L18.1849315,27.9452055 L24.6575342,27.9452055 L24.6575342,6.1890411 Z M17.3630137,28.3561644 L15.6493151,27.1232877 L13.9027397,28.3931507 L13.9027397,24.990411 C14.9609138,25.6533268 16.3048396,25.6533268 17.3630137,24.990411 L17.3630137,28.3561644 Z M15.6164384,24.6575342 C14.2546403,24.6575342 13.1506849,23.5535788 13.1506849,22.1917808 C13.1506849,20.8299828 14.2546403,19.7260274 15.6164384,19.7260274 C16.9782364,19.7260274 18.0821918,20.8299828 18.0821918,22.1917808 C18.0821918,23.5535788 16.9782364,24.6575342 15.6164384,24.6575342 Z M23.8356164,27.1232877 L18.1849315,27.1232877 L18.1849315,24.2465753 C19.274263,22.9052686 19.1393731,20.9496029 17.8762029,19.7705574 C16.6130328,18.5915119 14.6527207,18.5915119 13.3895505,19.7705574 C12.1263803,20.9496029 11.9914904,22.9052686 13.0808219,24.2465753 L13.0808219,27.1232877 L0.821917808,27.1232877 L0.821917808,0.821917808 L18.0821918,0.821917808 L23.8356164,6.5260274 L23.8356164,27.1232877 Z" />
                    </g>
                </g>
            </g>

        </IconBase>
    );
};
