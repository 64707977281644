import * as React from 'react';

import './style.scss';

interface Props {
    children: any;
    visible?: boolean;
}

export class InputError extends React.PureComponent<Props> {

    static defaultProps = {
        visible: false,
    };

    render() {

        if(!this.props.visible) return null;

        return (
            <span className="input-error">
                {this.props.children}
            </span>
        )
    }
}
