import format from 'date-fns/format';
import { parseISO } from 'date-fns'

import sv from 'date-fns/locale/sv';

export const formatTableDate = (value: Date) => {
    if(!value) return '';

    return format(parseISO(value.toString()), 'yyyy-MM-dd', {locale: sv});
};

export const formatSigningLogDate = (value: Date) => {
    if(!value) return '';

    return format(parseISO(value.toString()), 'yyyy-MM-dd - HH:mm', {locale: sv});
};