import * as React from 'react';

import './style.scss';

interface Props {}
interface State {}

export class Logo extends React.PureComponent<Props, State> {

    render() {
        return (
            <svg className="logo" version="1.1" viewBox="0 0 67 99">
                <path className="white-border" d="M33.6077387,98.8401031 C21.2110553,98.8401031 12.06,96.2171134 6.38688442,91.0493814 C0.915778894,86.0721649 0.212110553,78.5876289 0.212110553,72.6408247 L0.212110553,26.5360825 C0.212110553,20.5858763 0.915778894,13.1183505 6.38688442,8.12752577 C12.0498995,2.95639175 21.2009548,0.336804124 33.6077387,0.336804124 C46.0145226,0.336804124 55.1554774,2.95639175 60.8319598,8.12752577 C66.3030653,13.1251546 67,20.5960825 67,26.5360825 L67,72.6340206 C67,78.5808247 66.2963317,86.0483505 60.8252261,91.0425773 C55.1655779,96.2171134 46.0077889,98.8401031 33.6077387,98.8401031 Z M33.6077387,10.5497938 C28.0827638,10.5497938 18.0765327,11.2302062 13.1575879,15.7073196 C11.1913568,17.5002062 10.3126131,20.844433 10.3126131,26.5428866 L10.3126131,72.6408247 C10.3126131,78.3392784 11.1913568,81.6801031 13.1575879,83.4763918 C18.0765327,87.9637113 28.0827638,88.6339175 33.6077387,88.6339175 C39.1327136,88.6339175 49.1423116,87.9535052 54.0612563,83.4763918 C56.0274874,81.6801031 56.9062312,78.3392784 56.9062312,72.6408247 L56.9062312,26.5360825 C56.9062312,20.8376289 56.0274874,17.4934021 54.0612563,15.7005155 C49.1557789,11.2268041 39.1360804,10.5463918 33.6077387,10.5463918 L33.6077387,10.5497938 Z"/>
                <path className="beige-bg" d="M61.9497487,72.6408247 C61.9497487,78.7407216 61.0676382,83.9526804 57.438191,87.2696907 C53.3979899,90.971134 45.9438191,93.7336082 33.601005,93.7336082 C21.258191,93.7336082 13.8208543,90.9609278 9.7638191,87.2696907 C6.13773869,83.9594845 5.25562814,78.7475258 5.25562814,72.6408247 L5.25562814,26.5360825 C5.25562814,20.4361856 6.13773869,15.2242268 9.7638191,11.9072165 C13.8040201,8.2057732 21.2615578,5.44329897 33.601005,5.44329897 C45.9404523,5.44329897 53.3845226,8.21597938 57.438191,11.9072165 C61.0676382,15.2174227 61.9497487,20.4123711 61.9497487,26.5360825 L61.9497487,72.6408247 Z"/>
                <g className="bricks">
                    <g className="row01">
                        <path className="brick" d="M17.9788945,7.48453608 C15.4084244,8.24557484 12.987813,9.45070319 10.8243719,11.0464948 L17.9788945,11.0464948 L17.9788945,7.48453608 Z"/>
                        <path className="brick" d="M32.9141709,11.0430928 L32.9141709,5.44329897 C27.7258794,5.49092784 23.4230653,6.03185567 19.8946231,6.93 L19.8946231,11.0362887 L32.9141709,11.0430928 Z"/>
                        <path className="brick" d="M47.7686432,11.0430928 L47.7686432,7.05247423 C44.2469347,6.11010309 39.9373869,5.52494845 34.7154271,5.45350515 L34.7154271,11.0430928 L47.7686432,11.0430928 Z"/>
                        <path className="brick" d="M56.3944724,11.0430928 C54.3942203,9.56104651 52.1673525,8.41987354 49.8022111,7.66484536 L49.8022111,11.0430928 L56.3944724,11.0430928 Z"/>
                    </g>
                    <g className="row02">
                        <path className="brick" d="M11.8916583,12.4991753 L9.19819095,12.4991753 C7.63934673,14.1525773 6.66296482,16.2142268 6.07376884,18.5718557 L11.9017588,18.5718557 L11.8916583,12.4991753 Z"/>
                        <rect className="brick" x="13.8073869" y="12.4991753" width="13.0229146" height="6.07268041"/>
                        <polygon className="brick" points="28.6180905 18.5718557 30.2476382 18.5718557 30.2476382 16.3298969 28.6180905 16.3298969"/>
                        <polygon className="brick" points="40.1124623 16.3298969 40.1124623 18.5616495 41.6847739 18.5616495 41.6847739 16.3298969"/>
                        <rect className="brick" x="43.7149749" y="12.4991753" width="12.4875879" height="6.07268041"/>
                        <path className="brick" d="M57.7378392,18.5718557 L61.1551759,18.5718557 C60.5659799,16.2142268 59.589598,14.1491753 58.0307538,12.4991753 L57.7378392,12.4991753 L57.7378392,18.5718557 Z"/>
                    </g>
                    <g className="row03">
                        <path className="brick" d="M17.9788945,20.0313402 L5.75055276,20.0313402 C5.41876728,22.0379634 5.25769276,24.0696162 5.26909548,26.1040206 L17.9788945,26.1040206 L17.9788945,20.0313402 Z"/>
                        <polygon className="brick" points="30.2577387 20.0313402 19.8946231 20.0313402 19.8946231 26.1040206 30.2577387 26.1040206"/>
                        <polygon className="brick" points="47.5161307 20.0313402 40.1090955 20.0313402 40.1090955 26.1040206 47.7652764 26.1040206 47.7652764 20.0313402"/>
                        <path className="brick" d="M49.8022111,26.1040206 L61.9497487,26.1040206 C61.9611515,24.0696162 61.8000769,22.0379634 61.4682915,20.0313402 L49.8022111,20.0313402 L49.8022111,26.1040206 Z"/>
                    </g>
                    <g className="row04">
                        <rect className="brick" x="5.26236181" y="27.5601031" width="6.62929648" height="6.07268041"/>
                        <rect className="brick" x="13.8073869" y="27.5601031" width="13.0229146" height="6.07268041"/>
                        <polygon className="brick" points="28.6180905 27.556701 28.6180905 33.6293814 30.2476382 33.6293814 30.2476382 27.556701"/>
                        <polygon className="brick" points="40.1124623 33.6327835 41.6847739 33.6327835 41.6847739 27.556701 40.1124623 27.556701"/>
                        <polygon className="brick" points="43.7183417 33.6327835 56.2059296 33.6327835 56.2059296 27.5601031 55.9534171 27.5601031 43.7183417 27.5601031"/>
                        <rect className="brick" x="57.7378392" y="27.5601031" width="4.21864322" height="6.07268041"/>
                    </g>
                    <g className="row05">
                        <rect className="brick" x="5.26236181" y="35.092268" width="12.7131658" height="6.07268041"/>
                        <path className="brick" d="M25.379196,37.9942268 C25.836965,38.4613899 26.3557683,38.8631482 26.921206,39.1883505 C28.5574874,40.1035052 30.2577387,39.2734021 30.2577387,37.5961856 L30.2577387,35.092268 L19.8946231,35.092268 L19.8946231,41.1649485 L22.941608,41.1649485 L25.379196,37.9942268 Z"/>
                        <path className="brick" d="M40.1124623,35.092268 L40.1124623,38.392268 C40.1172781,39.3469349 39.9222228,40.2918371 39.5401005,41.1649485 L47.7686432,41.1649485 L47.7686432,35.092268 L40.1124623,35.092268 Z"/>
                        <rect className="brick" x="49.8022111" y="35.092268" width="12.1542714" height="6.07268041"/>
                    </g>
                    <g className="row06">
                        <rect className="brick" x="5.26236181" y="42.6210309" width="6.62929648" height="6.07268041"/>
                        <path className="brick" d="M25.9044221,43.9376289 C25.1704523,43.5395876 24.4061809,43.0428866 23.7900503,42.6210309 L13.8040201,42.6210309 L13.8040201,48.6937113 L26.8269347,48.6937113 L26.8269347,44.3730928 C26.4969849,44.2268041 26.1838693,44.0873196 25.9044221,43.9376289 Z"/>
                        <path className="brick" d="M35.2675879,44.9650515 C33.3148241,45.6964948 30.7728643,45.5297938 28.628191,44.9650515 L28.628191,48.6903093 L41.6847739,48.6903093 L41.6847739,42.6210309 L38.6546231,42.6210309 C37.7527505,43.6936741 36.5833129,44.5029913 35.2675879,44.9650515 Z"/>
                        <rect className="brick" x="43.7149749" y="42.6210309" width="12.4875879" height="6.07268041"/>
                        <rect className="brick" x="57.7378392" y="42.6210309" width="4.21864322" height="6.07268041"/>
                    </g>
                    <g className="row07">
                        <polygon className="brick" points="17.9384925 55.9026804 17.7566834 55.9026804 17.7566834 52.0515464 17.9384925 52.0515464 17.9384925 50.1531959 5.26236181 50.1531959 5.26236181 56.2258763 17.9384925 56.2258763"/>
                        <polygon className="brick" points="29.7796482 52.0515464 31.0927136 56.2258763 32.8737688 56.2258763 32.8737688 50.1531959 19.8508543 50.1531959 19.8508543 52.0515464"/>
                        <polygon className="brick" points="37.8432161 52.0515464 47.7282412 52.0515464 47.7282412 50.1531959 34.6716583 50.1531959 34.6716583 56.2258763 36.6008543 56.2258763"/>
                        <rect className="brick" x="49.761809" y="50.1531959" width="12.2148744" height="6.07268041"/>
                    </g>
                    <g className="row08">
                        <rect className="brick" x="5.26236181" y="57.6819588" width="6.62929648" height="6.07268041"/>
                        <polygon className="brick" points="20.3861809 57.6819588 13.8073869 57.6819588 13.8073869 63.7546392 18.5882915 63.7546392"/>
                        <polygon className="brick" points="31.5472362 57.6819588 33.4562312 63.7546392 34.3585427 63.7546392 36.1665327 57.6819588"/>
                        <polygon className="brick" points="47.2030151 57.6819588 49.0413065 63.7546392 56.2059296 63.7546392 56.2059296 57.6819588"/>
                        <rect className="brick" x="57.7378392" y="57.6819588" width="4.21864322" height="6.07268041"/>
                    </g>
                    <g className="row09">
                        <polygon className="brick" points="17.9788945 65.8230928 17.9788945 65.2141237 5.26236181 65.2141237 5.26236181 71.2868041 16.3628141 71.2868041"/>
                        <polygon className="brick" points="24.3422111 65.2141237 24.1368342 65.2141237 22.3423116 71.2868041 25.9515578 71.2868041"/>
                        <polygon className="brick" points="39.9912563 65.2141237 39.7724121 65.2141237 37.9543216 71.2868041 41.8261809 71.2868041"/>
                        <polygon className="brick" points="49.8022111 65.2141237 49.8022111 66.2721649 51.3172864 71.2868041 61.9564824 71.2868041 61.9564824 65.2141237"/>
                    </g>
                    <g className="row10">
                        <path className="brick" d="M11.3192965,77.6758763 L11.8916583,77.6758763 L11.8916583,72.7428866 L5.26572864,72.7428866 C5.24773482,74.7753973 5.39072042,76.8061044 5.69331658,78.815567 L11.3192965,78.815567 L11.3192965,77.6758763 Z"/>
                        <polygon className="brick" points="14.4740201 77.6758763 15.9318593 72.7428866 13.8073869 72.7428866 13.8073869 77.6758763"/>
                        <polygon className="brick" points="26.3387437 72.7428866 21.9113568 72.7428866 20.4501508 77.6758763 22.881005 77.6758763 22.881005 78.815567 26.8303015 78.815567 26.8303015 74.6072165"/>
                        <polygon className="brick" points="41.4086935 77.6758763 41.6847739 77.6758763 41.6847739 72.7428866 37.5166332 72.7428866 35.6985427 78.815567 41.4086935 78.815567"/>
                        <polygon className="brick" points="51.7583417 72.7428866 53.2498492 77.6758763 55.731206 77.6758763 55.731206 78.815567 56.2059296 78.815567 56.2059296 72.7428866"/>
                        <path className="brick" d="M57.7378392,78.815567 L61.5255276,78.815567 C61.826997,76.806013 61.9688567,74.7753064 61.9497487,72.7428866 L57.7378392,72.7428866 L57.7378392,78.815567 Z"/>
                    </g>
                    <g className="row11">
                        <path className="brick" d="M11.3192965,81.5270103 L11.3192965,80.2886598 L5.97949749,80.2886598 C6.52155779,82.6292784 7.43396985,84.6875258 8.89180905,86.3613402 L17.9822613,86.3613402 L17.9822613,81.5270103 L11.3192965,81.5270103 Z"/>
                        <polygon className="brick" points="28.6618593 81.5270103 28.3319095 80.2716495 22.881005 80.2716495 22.881005 81.5270103 19.8912563 81.5270103 19.8912563 86.347732 32.9141709 86.347732 32.9141709 81.5270103"/>
                        <polygon className="brick" points="41.4086935 81.5270103 41.4086935 80.2716495 35.2642211 80.2716495 34.8871357 81.5270103 34.7154271 81.5270103 34.7154271 86.347732 47.7686432 86.347732 47.7686432 81.5270103"/>
                        <path className="brick" d="M55.731206,80.2886598 L55.731206,81.5406186 L49.8022111,81.5406186 L49.8022111,86.3613402 L58.3270352,86.3613402 C59.7848744,84.6875258 60.6838191,82.6190722 61.2393467,80.2886598 L55.731206,80.2886598 Z"/>
                    </g>
                    <g className="row12">
                        <path className="brick" d="M11.1812563,87.8038144 L11.1812563,88.4059794 C14.251809,90.637732 18.7768342,92.4102062 25.1569849,93.2335052 L25.1569849,87.7902062 L11.1812563,87.8038144 Z"/>
                        <path className="brick" d="M26.9548744,87.8038144 L26.9548744,93.4342268 C28.9951759,93.6281443 31.2105528,93.7370103 33.6077387,93.7370103 C35.9687282,93.7458002 38.3286107,93.6322491 40.6780905,93.3968041 L40.6780905,87.8038144 L26.9548744,87.8038144 Z"/>
                        <path className="brick" d="M42.718392,87.8038144 L42.718392,93.1416495 C48.7079899,92.291134 53.0074372,90.586701 55.9702513,88.4536082 L55.9702513,87.8038144 L42.718392,87.8038144 Z"/>
                    </g>
                </g>
                <path className="letter-fill letter-J" d="M22.5207538,41.7126804 L25.379196,37.9942268 C25.836965,38.4613899 26.3557683,38.8631482 26.921206,39.1883505 C28.5574874,40.1035052 30.2577387,39.2734021 30.2577387,37.5961856 L30.2577387,16.3298969 L27.8773869,16.3298969 L27.8773869,12.485567 L42.4759799,12.485567 L42.4759799,16.3298969 L40.119196,16.3298969 L40.119196,38.3820619 C40.119196,41.2704124 38.4795477,43.7573196 35.2743216,44.9548454 C32.3754774,46.0401031 28.1770352,45.1521649 25.9111558,43.9342268 C24.7346832,43.2685486 23.6021727,42.5264759 22.5207538,41.7126804 Z"/>
                <path className="letter-border letter-J" d="M32.2307035,45.9278351 C29.9563947,45.9216068 27.714215,45.3848853 25.6788442,44.3594845 C24.4792138,43.6827074 23.3242173,42.9281387 22.2211055,42.1005155 L21.8339196,41.8045361 L25.3320603,37.2525773 L25.7158794,37.6608247 C26.1426648,38.0913476 26.6237819,38.4631102 27.1467839,38.7664948 C27.7332416,39.1456982 28.474079,39.1844852 29.0961809,38.8685567 C29.5360288,38.602705 29.7949578,38.1134416 29.7695477,37.5961856 L29.7695477,16.8231959 L27.389196,16.8231959 L27.389196,12.0024742 L42.9507035,12.0024742 L42.9507035,16.8231959 L40.5939196,16.8231959 L40.5939196,38.392268 C40.5939196,41.6310309 38.7152261,44.1757732 35.4359296,45.4209278 C34.4048178,45.7769322 33.3201541,45.948472 32.2307035,45.9278351 Z M23.2042211,41.6140206 C24.1412603,42.3029537 25.1182668,42.9345985 26.13,43.505567 C28.4699497,44.771134 32.4630151,45.4923711 35.0992462,44.5057732 C37.9812563,43.4307216 39.631005,41.1989691 39.631005,38.3820619 L39.631005,15.8536082 L41.9877889,15.8536082 L41.9877889,12.9720619 L28.3554774,12.9720619 L28.3554774,15.8536082 L30.7358291,15.8536082 L30.7358291,37.5961856 C30.7590348,38.4547583 30.3229089,39.2594387 29.5944724,39.7020619 C28.6792553,40.1966182 27.5729875,40.1629404 26.6888945,39.6136082 C26.240775,39.3574378 25.8199468,39.0553296 25.4330653,38.7120619 L23.2042211,41.6140206 Z"/>
                <polygon className="letter-fill letter-M" points="20.911407 55.9026804 17.7566834 55.9026804 17.7566834 52.0515464 29.7796482 52.0515464 33.9208543 65.227732 37.8432161 52.0515464 49.5564322 52.0515464 49.5564322 55.9026804 46.6643216 55.9026804 53.2498492 77.6758763 55.731206 77.6758763 55.731206 81.5270103 41.4086935 81.5270103 41.4086935 77.6758763 43.7587437 77.6758763 39.8801508 64.846701 34.8871357 81.5270103 28.6618593 81.5270103 24.2445729 64.846701 20.4501508 77.6758763 22.881005 77.6758763 22.881005 81.5270103 11.3192965 81.5270103 11.3192965 77.6758763 14.4740201 77.6758763"/>
                <path className="letter-border letter-M" d="M56.2261307,82.0101031 L40.930603,82.0101031 L40.930603,77.1893814 L43.0954774,77.1893814 L39.8700503,66.5137113 L35.2305528,82.0101031 L28.281407,82.0101031 L24.2075377,66.6259794 L21.0831156,77.1893814 L23.348995,77.1893814 L23.348995,82.0101031 L10.841206,82.0101031 L10.841206,77.1893814 L14.1171357,77.1893814 L20.2683417,56.3857732 L17.278593,56.3857732 L17.278593,51.5684536 L30.129799,51.5684536 L33.9073869,63.5879381 L37.4896985,51.5684536 L50.0345226,51.5684536 L50.0345226,56.3857732 L47.3141206,56.3857732 L53.6067337,77.1893814 L56.2261307,77.1893814 L56.2261307,82.0101031 Z M41.9069849,81.0405155 L55.2497487,81.0405155 L55.2497487,78.1589691 L52.8929648,78.1589691 L46.0145226,55.4161856 L49.071608,55.4161856 L49.071608,52.5346392 L38.1967337,52.5346392 L33.9275879,66.8675258 L29.4261307,52.5346392 L18.2381407,52.5346392 L18.2381407,55.4161856 L21.5477387,55.4161856 L14.8140704,78.1589691 L11.7839196,78.1589691 L11.7839196,81.0405155 L22.3860804,81.0405155 L22.3860804,78.1589691 L19.7902513,78.1589691 L24.2546734,63.0674227 L29.0153769,81.0405155 L34.5167839,81.0405155 L39.8633166,63.1796907 L44.3883417,78.1487629 L41.8733166,78.1487629 L41.9069849,81.0405155 Z"/>
                <path className="black-border" d="M33.6077387,94.2371134 C22.5342211,94.2371134 14.1811055,91.957732 9.45070352,87.6405155 C5.33979899,83.8982474 4.78427136,77.9582474 4.78427136,72.6714433 L4.78427136,26.5360825 C4.78427136,21.232268 5.33979899,15.3092784 9.45070352,11.5670103 C14.1811055,7.24979381 22.5342211,4.97041237 33.6077387,4.97041237 C44.6812563,4.97041237 53.0377387,7.24979381 57.7681407,11.5670103 C61.8790452,15.3092784 62.4345729,21.2458763 62.4345729,26.5360825 L62.4345729,72.6340206 C62.4345729,77.934433 61.8790452,83.8608247 57.7681407,87.6030928 C53.0377387,91.9407216 44.6846231,94.2371134 33.6077387,94.2371134 Z M33.6077387,5.94680412 C19.9249246,5.94680412 13.2686935,9.39989691 10.0937688,12.2984536 C7.04341709,15.0779381 5.7438191,19.3441237 5.7438191,26.5598969 L5.7438191,72.6578351 C5.7438191,79.8702062 7.04341709,84.1363918 10.0937688,86.9192784 C13.2686935,89.8178351 19.9249246,93.2675258 33.6077387,93.2675258 C47.2905528,93.2675258 53.9501508,89.8178351 57.1250754,86.9192784 C60.1754271,84.1363918 61.4750251,79.8702062 61.4750251,72.6578351 L61.4750251,26.5360825 C61.4750251,19.3203093 60.1754271,15.0541237 57.1250754,12.2746392 C53.9501508,9.3828866 47.2939196,5.92979381 33.6077387,5.92979381 L33.6077387,5.94680412 Z"/>
            </svg>
        )
    }
}
