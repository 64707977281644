import * as React from 'react';

import classnames from 'classnames';

import './style.scss';

import {Icon} from 'components/atoms/Icon';
import {Label} from 'components/atoms/formElements/Label';
import {InputError} from 'components/atoms/formElements/InputError';

interface Props {
    name: string;
    type: string;
    id: string;
    className?: string;
    value?: string;
    defaultValue?: string;
    placeholder?: string;
    onBlur?: (value: string, name: string) => any;
    onFocus?: () => any;
    onChange?: (value: string, name: string) => any;
    valid?: boolean;
    textarea?: boolean;
    pattern?: string;
    disabled?: boolean;
    messages: string[];
    pristine?: boolean;
    tabIndex?: number;
    autoCapitalize?: string;
    innerRef?: any;
    'data-test-id'?: string;
}

interface State {
    pristine: boolean;
    active: boolean;
    value: string;
    showPassword: boolean;
    largePlaceholder: boolean;
}

export class Input extends React.PureComponent<Props> {

    static defaultProps = {
        defaultValue: ''
    };

    state: State = {
        pristine: (typeof this.props.pristine === 'undefined') ? true : this.props.pristine,
        active: false,
        value: (typeof this.props.value !== 'undefined') ? this.props.value : Input.defaultProps.defaultValue,
        showPassword: false,
        largePlaceholder: (this.props.value && this.props.value.length > 0) ? false : true,
    };

    inputRef = this.props.innerRef ? this.props.innerRef : React.createRef<any>();

    isInvalid = () => {
        return typeof this.props.valid !== 'undefined' && !this.props.valid;
    };

    componentWillReceiveProps = (nextProps: Props) => {
        if(this.state.largePlaceholder && nextProps.value && nextProps.value.length > 0) {
            this.setState({
                largePlaceholder: false,
            });
        }

        if(!this.state.largePlaceholder && nextProps.value && nextProps.value.length === 0) {
            this.setState({
                largePlaceholder: true,
            });
        }

        if(this.props.pristine !== nextProps.pristine) {
            this.setState({
                pristine: nextProps.pristine,
            })
        }
    };

    internalOnBlur = () => {
        this.setState({
            active: false,
            pristine: false,
        });

        if(this.inputRef.current === null) {
            console.error('Input reference not defined', this.props.name);
            return;
        }

        if(!this.props.value) {
            this.setState({
                largePlaceholder: true,
            });
        }

        this.props.onBlur && this.props.onBlur(this.inputRef.current.value, this.inputRef.current.name);
    };

    internalOnFocus = () => {
        this.setState({
            active: true,
            largePlaceholder: false,
        });

        this.props.onFocus && this.props.onFocus();
    };

    internalOnChange = () => {
        if(this.inputRef.current === null) {
            console.error('Input reference not defined', this.props.name);
            return;
        }

        this.props.onChange && this.props.onChange(this.inputRef.current.value, this.inputRef.current.name);
    };

    togglePassword = () => {
        this.setState({
            showPassword: !this.state.showPassword,
        })
    };

    render() {
        let classes = classnames('input-field', this.props.className,  {
            'input-field--valid': this.props.valid,
            'input-field--invalid': this.isInvalid(),
            'input-field--dirty': !this.state.pristine,
            'input-field--pristine': this.state.pristine,
            'input-field--active': this.state.active,
            'input-field--disabled': this.props.disabled,
        });


        return (
            <React.Fragment>
                <div className={classes}>
                    {this.props.placeholder && <Label htmlFor={this.props.id} large={this.state.largePlaceholder}>{this.props.placeholder}</Label>}
                    {this.props.textarea &&
                        <textarea
                            name={this.props.name}
                            id={this.props.id}
                            data-test-id={this.props["data-test-id"]}
                            value={this.props.value}
                            disabled={this.props.disabled}
                            onFocus={this.internalOnFocus}
                            onBlur={this.internalOnBlur}
                            onChange={this.internalOnChange}
                            ref={this.inputRef}
                            tabIndex={this.props.tabIndex}

                        />
                    }
                    {!this.props.textarea &&
                        <input
                            type={(this.props.type === "password" && this.state.showPassword) ? "text" : this.props.type}
                            name={this.props.name}
                            id={this.props.id}
                            data-test-id={this.props["data-test-id"]}
                            disabled={this.props.disabled}
                            pattern={this.props.pattern}
                            value={this.props.value}
                            onFocus={this.internalOnFocus}
                            onBlur={this.internalOnBlur}
                            onChange={this.internalOnChange}
                            ref={this.inputRef}
                            autoCapitalize={this.props.autoCapitalize ? this.props.autoCapitalize : 'on'}
                            tabIndex={this.props.tabIndex}
                            formNoValidate
                        />
                    }
                    <span className="input-field__validation-icon">
                        { this.props.type !== "password" && this.props.valid && !this.state.pristine && <Icon name="input-ok" /> }
                        { this.props.type !== "password" && this.isInvalid() && !this.state.pristine && <Icon name="input-error" /> }
                        { this.props.type === "password" && this.state.showPassword && <Icon name="password-hide" onClick={this.togglePassword} /> }
                        { this.props.type === "password" && !this.state.showPassword && <Icon name="password-show" onClick={this.togglePassword} /> }
                    </span>
                    {!this.state.pristine && <InputError visible={this.props.messages.length > 0}>{this.props.messages[0]}</InputError>}
                </div>

            </React.Fragment>
        )
    }
}
