import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const Instagram: React.SFC<IconProps> = (props) => {
    const {width, height} = props;

    return (
        <IconBase fill viewPortWidth={512} viewPortHeight={512} height={height} width={width}>
            <g id="Instagram-Logo-515x515" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <defs>
                    <circle id="insta-path-1" cx="256" cy="256" r="256"></circle>
                    <linearGradient x1="66.8139539%" y1="-10.5072748%" x2="98.3430837%" y2="111.45127%" id="insta-linearGradient-3">
                        <stop stopColor="#4E60D3" offset="0%"></stop>
                        <stop stopColor="#913BAF" offset="14.2763214%"></stop>
                        <stop stopColor="#D52D88" offset="76.145798%"></stop>
                        <stop stopColor="#F26D4F" offset="100%"></stop>
                    </linearGradient>
                    <radialGradient cx="30.2743283%" cy="100%" fx="30.2743283%" fy="100%" r="124.486067%" gradientTransform="translate(0.302743,1.000000),scale(0.692765,1.000000),rotate(23.537155),scale(1.000000,0.719668),translate(-0.302743,-1.000000)" id="insta-radialGradient-4">
                        <stop stopColor="#FED276" offset="0%"></stop>
                        <stop stopColor="#FDBD61" stopOpacity="0.975005661" offset="17.0240041%"></stop>
                        <stop stopColor="#F6804D" offset="45.4081253%"></stop>
                        <stop stopColor="#E83D5C" stopOpacity="0" offset="100%"></stop>
                    </radialGradient>
                    <rect id="insta-path-5" x="85" y="87" width="343" height="341" rx="91"></rect>
                    <filter x="-8.7%" y="-8.5%" width="117.5%" height="117.6%" filterUnits="objectBoundingBox" id="insta-filter-6">
                        <feMorphology radius="17.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
                        <feOffset dx="0" dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
                        <feMorphology radius="17.5" operator="erode" in="SourceAlpha" result="shadowInner"></feMorphology>
                        <feOffset dx="0" dy="1" in="shadowInner" result="shadowInner"></feOffset>
                        <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"></feComposite>
                        <feGaussianBlur stdDeviation="4" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.136011096 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                    </filter>
                    <circle id="insta-path-7" cx="257" cy="259" r="82"></circle>
                    <filter x="-18.3%" y="-17.7%" width="136.6%" height="136.6%" filterUnits="objectBoundingBox" id="insta-filter-8">
                        <feMorphology radius="17.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
                        <feOffset dx="0" dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
                        <feMorphology radius="17.5" operator="erode" in="SourceAlpha" result="shadowInner"></feMorphology>
                        <feOffset dx="0" dy="1" in="shadowInner" result="shadowInner"></feOffset>
                        <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"></feComposite>
                        <feGaussianBlur stdDeviation="4" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.136011096 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                    </filter>
                    <circle id="insta-path-9" cx="357.5" cy="157" r="24"></circle>
                    <filter x="-26.0%" y="-24.0%" width="152.1%" height="152.1%" filterUnits="objectBoundingBox" id="insta-filter-10">
                        <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                        <feGaussianBlur stdDeviation="4" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.136011096 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                    </filter>
                </defs>
                <mask id="insta-mask-2" fill="white">
                    <use xlinkHref="#insta-path-1"></use>
                </mask>
                <path d="M390,-9.03625001e-15 C464,-9.03625001e-15 512,48 512,122 L512,390 C512,464 464,512 390,512 L122,512 C48,512 0,464 0,390 L0,122 C0,48 48,-9.03624997e-15 122,-9.03624997e-15 L390,-9.03625001e-15 Z" id="insta-Background" fill="url(#insta-linearGradient-3)" mask="url(#insta-mask-2)"></path>
                <path d="M508.109002,424.701394 C495.293995,478.65427 451.678133,512 390,512 L122,512 C48,512 5.68434189e-14,464 5.68434189e-14,390 L5.68434189e-14,178.551167 C40.7132957,166.547645 84.6625993,160 130.5,160 C322.626081,160 481.581279,275.031777 508.109002,424.701394 L508.109002,424.701394 Z" id="insta-Yellow-Flare" fill="url(#insta-radialGradient-4)" mask="url(#insta-mask-2)"></path>
                <g id="insta-Camera-Frame" mask="url(#insta-mask-2)">
                    <use fill="black" fillOpacity="1" filter="url(#insta-filter-6)" xlinkHref="#insta-path-5"></use>
                    <use stroke="#FFFFFF" strokeWidth="35" xlinkHref="#insta-path-5"></use>
                </g>
                <g id="insta-Oval-1" mask="url(#insta-mask-2)">
                    <use fill="black" fillOpacity="1" filter="url(#insta-filter-8)" xlinkHref="#insta-path-7"></use>
                    <use stroke="#FFFFFF" strokeWidth="35" xlinkHref="#insta-path-7"></use>
                </g>
                <g id="insta-Oval-2" mask="url(#insta-mask-2)">
                    <use fill="black" fillOpacity="1" filter="url(#insta-filter-10)" xlinkHref="#insta-path-9"></use>
                    <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#insta-path-9"></use>
                </g>
            </g>
        </IconBase>
    );
};
