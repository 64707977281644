import React from 'react';
import ReactDOM from 'react-dom';
import {App} from './containers/App';
import {BrowserRouter as Router} from 'react-router-dom';

import 'main.scss';
import { AuthenticationQrProvider } from 'services/authentication/authentication-qr-context';

ReactDOM.render(
        <Router>
            <AuthenticationQrProvider>
                <App />
            </AuthenticationQrProvider>
        </Router>,
    document.getElementById('root'));
