import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const Plus: React.SFC<IconProps> = (props) => {
    const {width, height} = props;

    return (
        <IconBase fill viewPortWidth={12} viewPortHeight={12} height={height} width={width}>
            <polygon points="12 6.85714286 6.85714286 6.85714286 6.85714286 12 5.14285714 12 5.14285714 6.85714286 0 6.85714286 0 5.14285714 5.14285714 5.14285714 5.14285714 0 6.85714286 0 6.85714286 5.14285714 12 5.14285714"></polygon>
        </IconBase>
    );
};
