import * as React from 'react';
import {Container} from 'components/atoms/Container';
import {BankIDQrForm} from 'components/organisms/BankIDQrForm';

interface Props {
}

export default class LoginPage extends React.PureComponent<Props> {

    render() {
        return (
            <Container>
                <BankIDQrForm />
            </Container>
        )
    }

}