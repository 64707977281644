import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const ExternalLink: React.SFC<IconProps> = (props) => {
    const {width, height} = props;

    return (
        <IconBase fill viewPortWidth={20} viewPortHeight={20} height={height} width={width}>
            <g>
                <g transform="translate(14.015663, 6.087297) rotate(-44.000000) translate(-14.015663, -6.087297) translate(6.515663, 2.087297)">
                    <polygon points="11.0405792 4.54747351e-13 10.1896275 0.850976714 12.5873926 3.29091688 0.172003658 3.34027974 0.172003658 4.57539836 12.5873926 4.5260355 10.1896275 6.96269932 11.0405792 7.83648287 14.9132057 3.90597503"></polygon>
                </g>
                <path d="M8,4.03030303 L2.03225806,4.03030303 L2.03225806,18.969697 L15.9677419,18.969697 L15.9677419,12 L17,12 L17,20 L1,20 L1,3 L8,3 L8,4.03030303 Z"></path>
            </g>
        </IconBase>
    );
};
