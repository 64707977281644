import * as React from 'react';
import {getDocuments} from 'services/documents/documents-service';
import {CooperativeModel} from 'services/documents/types';

// @ts-ignore
export const DocumentsContext = React.createContext();
export const DocumentsConsumer = DocumentsContext.Consumer;

interface Props {
    children: any;
}

export interface DocumentsContextType {
    models: CooperativeModel[];
    loading: boolean;
}

export class DocumentsProvider extends React.PureComponent<Props, DocumentsContextType> {

    async componentDidMount() {

        let response: CooperativeModel[] = await getDocuments();

        if (typeof response !== 'undefined') {
            this.setState({
                models: response,
                loading: false,
            });
        }

    }

    state = {
        loading: true,
        models: [],
    };

    render() {
        return (
            <DocumentsContext.Provider value={this.state}>
                {this.props.children(this.state)}
            </DocumentsContext.Provider>
        )
    }
}