import * as React from 'react';

import classnames from 'classnames';

import './style.scss';

import {Icon} from 'components/atoms/Icon';

interface Props {
    error?: boolean;
    success?: boolean;
    info?: boolean;
    clickToBuy?: boolean;
    centered?: boolean;
    children: any;
}

export class Message extends React.PureComponent<Props> {
    render() {

        let messageClasses = classnames('message', {
            'message--error': this.props.error,
            'message--success': this.props.success,
            'message--info': this.props.info,
            'message--click-to-buy': this.props.clickToBuy,
            'message--centered': this.props.centered
        });

        return (
            <div className={messageClasses}>
                <Icon name="input-error" />
                <div>{this.props.children}</div>
            </div>
        )
    }
}
