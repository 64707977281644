import * as React from 'react';
import {IconBase} from 'components/atoms/Icons/_iconBase';
import {IconProps} from 'components/atoms/Icons/types';

export const Hamburger: React.SFC<IconProps> = (props) => {
    const {height, width} = props;

    return (
        <IconBase fill viewPortWidth={14} viewPortHeight={14} height={height} width={width}>
            <g>
                <polygon points="0 2 14 2 14 0 0 0"/>
                <polygon points="0 8 14 8 14 6 0 6"/>
                <polygon points="0 14 14 14 14 12 0 12"/>
            </g>
        </IconBase>
    );
};
