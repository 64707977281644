import * as React from 'react';

import './style.scss';
import {H4} from "../../atoms/H4";
import {P} from "../../atoms/P";
import {Button} from "../../atoms/Button";
import {Input} from "../../atoms/formElements/Input";
import {A} from "../../atoms/A";
import {sendMessage} from 'services/documents/documents-service';
import {Contract} from 'services/documents/types';
import {Icon} from "components/atoms/Icon";

interface Props {
    objectId: string;
    model: Contract;
}

interface State {
    sendMessage: boolean;
    message: string;
    messageSent: boolean;
}

export class MessageBox extends React.PureComponent<Props, State> {

    state = {
        sendMessage: false,
        message: this.props.model.messageFromCoorperative !== null ? this.props.model.messageFromCoorperative : '',
        messageSent: false,
    };

    onToggle = () => {
        this.setState({
            sendMessage: !this.state.sendMessage,
        });
    };

    onChange = (value: string, name: string) => {
        this.setState({
            message: value,
        })
    };

    sendMessage = async () => {
        let response = await sendMessage(this.props.objectId, this.props.model.contractId, this.state.message);

        if(response === "Message sent") {
            this.setState({
                sendMessage: !this.state.sendMessage,
            })
        }

    };

    render() {

        console.log(this.props.model);

        return (
            <div className="message-box">

                {this.props.model.messageFromCoorperative === null &&
                <div>
                    <H4 serif className="message-box__title">Meddelande från JM</H4>
                    <P className="message-box__message no-color"><i>Inget meddelande</i></P>
                </div>
                }

                {this.props.model.messageToCoorperative &&
                    <div>
                        <H4 serif className="message-box__title">Meddelande från JM</H4>
                        <P className="message-box__message">{this.props.model.messageToCoorperative}</P>
                    </div>
                }

                {(this.state.sendMessage && !this.state.messageSent) &&
                    <React.Fragment>
                        <Input
                            name="message-to-jm"
                            type="text"
                            textarea
                            onChange={this.onChange}
                            id="message-to-jm"
                            placeholder="Meddelande till JM"
                            messages={[]}
                            value={this.state.message}
                        />

                        <Button onClick={this.sendMessage} small primary>Spara</Button> <A href="#" className="message-box__cancel" onClick={this.onToggle}>Avbryt</A>
                    </React.Fragment>
                }

                {/*this.state.messageSent &&
                    <div style={{textAlign: "right"}} className="message-box">
                        <P>Tack, ditt meddelande är nu skickat.</P>
                    </div>
                */}

                {!this.state.sendMessage &&
                    <>
                        <div className="message-box__edit-container">
                            <Icon name="edit" />
                            <A href="#" onClick={this.onToggle}>{this.props.model.messageFromCoorperative ? 'Redigera anteckning till JM' : 'Anteckning till JM'}</A>
                        </div>
                        <p className="message-box__from-cooperative">
                          <span style={{ whiteSpace: "pre-line" }}>{this.state.message}</span>
                        </p>
                    </>
                }
            </div>
        )
    }
}
