import * as React from 'react';

import classnames from 'classnames';

import './style.scss';

interface Props {
    children?: any;
    dangerouslySetInnerHTML?: { __html: string }
    id?:string;
    className?: string;
    preamble?: boolean;
    large?: boolean;
    small?: boolean;
    tiny?: boolean;
    centered?: boolean;
    serif?: boolean;
}

export class P extends React.PureComponent<Props> {
    render() {

        let pClasses = classnames(this.props.className, {
            'preamble': this.props.preamble,
            'large': this.props.large,
            'small': this.props.small,
            'fineprint': this.props.tiny,
            'centered': this.props.centered,
            'serif': this.props.serif,
        });

        if(this.props.dangerouslySetInnerHTML) {
            return (
                <p
                    id={this.props.id}
                    className={pClasses}
                    dangerouslySetInnerHTML={this.props.dangerouslySetInnerHTML}
                />
            );
        }

        return (
            <p
                id={this.props.id}
                className={pClasses}
            >
                { this.props.children }
            </p>
        )
    }
}
