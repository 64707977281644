import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const House: React.SFC<IconProps> = (props) => {
    const {width, height} = props;

    return (
        <IconBase viewPortWidth={31} viewPortHeight={30} height={height} width={width}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-1.000000, 0.000000)" fill="currentColor">
                    <g transform="translate(1.000000, 0.000000)">
                        <path d="M30.3173913,15.3478261 L23.9130435,8.95652174 L23.9130435,2.17391304 L20.4347826,2.17391304 L20.4347826,5.47826087 L15.2173913,0.260869565 L0.134782609,15.3478261 L0.752173913,15.9652174 L2.18695652,14.5304348 L2.18695652,29.973913 L28.2608696,29.973913 L28.2608696,14.5130435 L29.7130435,15.9652174 L30.3173913,15.3478261 Z M21.3043478,3.04347826 L23.0434783,3.04347826 L23.0434783,8.08695652 L21.3043478,6.34782609 L21.3043478,3.04347826 Z M18.2608696,29.1043478 L12.173913,29.1043478 L12.173913,18.7434783 L18.2608696,18.7434783 L18.2608696,29.1043478 Z M27.3913043,29.1043478 L19.1304348,29.1043478 L19.1304348,17.873913 L11.3043478,17.873913 L11.3043478,29.1043478 L3.04347826,29.1043478 L3.04347826,13.6608696 L15.2173913,1.48695652 L27.3913043,13.6434783 L27.3913043,29.1043478 Z"/>
                    </g>
                </g>
            </g>
        </IconBase>
    );
};
