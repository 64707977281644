import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const Chevron: React.SFC<IconProps> = (props) => {
    const {height, width} = props;

    return (
        <IconBase fill viewPortWidth={12} viewPortHeight={9} height={height} width={width}>
            <polygon points="1.41 0.84 6 5.42 10.59 0.84 12 2.25 6 8.25 0 2.25"/>
        </IconBase>
    );
};
