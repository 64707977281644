import * as React from 'react';
import {IconBase} from 'components/atoms/Icons/_iconBase';
import {IconProps} from 'components/atoms/Icons/types';

export const FlagNo: React.SFC<IconProps> = (props) => {
    const {height, width} = props;

    return (
        <IconBase fill viewPortWidth={30} viewPortHeight={30} height={height} width={width}>
            <g>
                <mask fill="white" id="NoCircle">
                    <path d="M29.8122239,14.9663158 C29.8122239,23.2052632 23.1736343,29.8857895 14.9852383,29.8857895 C6.79684231,29.8857895 0.158252615,23.2052632 0.158252615,14.9663158 C0.158252615,6.72631579 6.79684231,0.0463157895 14.9852383,0.0463157895 C23.1736343,0.0463157895 29.8122239,6.72631579 29.8122239,14.9663158 L29.8122239,14.9663158 Z" />
                </mask>
                <polygon fill="#E13200" mask="url(#NoCircle)" points="0.1 29.8153623 29.9384211 29.8153623 29.9384211 0.162960093 0.1 0.162960093"/>
                <polyline fill="#FFFFFF" mask="url(#NoCircle)" points="40.2070175 11.1481209 14.2180702 11.1481209 14.2180702 0.162960093 6.48754386 0.162960093 6.48754386 11.1481209 -4.55035088 11.1481209 -4.55035088 18.9792716 6.48754386 18.9792716 6.48754386 29.8153623 14.2180702 29.8153623 14.2180702 18.9792716 40.2070175 18.9792716 40.2070175 11.1481209"/>
                <polyline fill="#002B9E" mask="url(#NoCircle)" points="12.2785965 0.162960093 8.42649123 0.162960093 8.42649123 13.0745254 -4.55035088 13.0745254 -4.55035088 17.051821 8.42649123 17.051821 8.42649123 29.8153623 12.2785965 29.8153623 12.2785965 17.051821 40.134386 17.051821 40.134386 13.0745254 12.2785965 13.0745254 12.2785965 0.162960093"/>
            </g>
        </IconBase>
    );
};
