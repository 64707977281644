import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const InputError: React.SFC<IconProps> = (props) => {
    const {width, height} = props;

    return (
        <IconBase viewPortWidth={20} viewPortHeight={18} height={height} width={width}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(0.000000, -1.000000)" fill="#a50000">
                    <g transform="translate(0.000000, 1.000000)">
                        <polygon className="fill" points="19.8540541 17.2972973 0.156756757 17.2972973 10 0"></polygon>
                        <rect className="icon" x="9.45945946" y="5.94594595" width="1.08108108" height="6.21621622"></rect>
                        <circle className="icon" cx="10" cy="14.3243243" r="1"></circle>
                        <path d="M19.8540541,17.2972973 L0.156756757,17.2972973 L10,0 L19.8540541,17.2972973 Z M2.01621622,16.2162162 L17.9945946,16.2162162 L10,2.16216216 L2.01621622,16.2162162 Z"></path>
                    </g>
                </g>
            </g>
        </IconBase>
    );
};
