import * as React from 'react';

import './style.scss';
import {DataTable} from 'components/molecules/DataTable';
import {formatTableDate} from "services/utils/date-helper";
import {Icon} from "../../../atoms/Icon";
import {RouteComponentProps, withRouter} from "react-router";
import {CooperativeObject} from "../../../../services/documents/types";
import {A} from "../../../atoms/A";
import {ContractFilter} from "../../../pages/ArchivePage";


interface Props extends RouteComponentProps {
    data: CooperativeObject[];
    cooperativeId: string;
    currentActiveFilter: ContractFilter;
}

interface State {
}

export class ArchiveTableComp extends React.PureComponent<Props, State> {


    _defaultSorted: any = {
        id: 'residenceObjectNumber',
        desc: false,
    };


    componentDidMount(): void {
        this.setupUrls();
    }

    renderAsDate = (value: Date, data: any) => {
        return formatTableDate(value);
    };

    renderWithStatus = (value: string, data: any) => {
        return (
            <React.Fragment>
                <Icon name="green" />
                {value}
            </React.Fragment>
        )
    };

    renderFacts = (value: any, data: any) => {
        return `${data.numberOfRooms} Rok, ${data.livingArea} kvm`;
    };

    onRowClick = (data) => (e) => {
        e.preventDefault();

        this.props.history.push(data.url);
    };

    setupUrls = () => {
        this.setState({
            data: this.props.data.map(x => ({
                ...x,
                url: `/object-page/${this.props.location.pathname.replace('/archive/', '')}/${x.residenceObjectId}`,
            })),
        }, () => {
        });

    };

    renderSignedStatus = (value: any, data) => {
        if(!value) return null;
        return (<div className="archive-table__status" />)
    };

    renderStatus = (value: any, data) => {

        //let statusClass = data.status === 155330016 ? 'done'  : 'pending';

        if(data.status === 1) return 'Till salu';
        if(data.status === 155330001) return 'Bokad';
        if(data.status === 155330000) return 'Reserverad';

        return (
            <div className="archive-table__status-row">
                 {value}
            </div>
        )

    }

    renderChildStatus = (value: any, data) => {
        let statusClass = !value ? 'pending' : 'done';

        let statusValue = !value ? 'Icke signerat' : 'Signerat'

        return (
            <div className="archive-table__status-row">
                <div className={`archive-table__status ${statusClass}`} /> {statusValue}
            </div>
        )
    }

    renderObjectLink = (value: any, data) => {

        return (
            <div>
                <A href={`/object-page/${this.props.location.pathname.replace('/archive/', '')}/${data.residenceObjectId}`} route>Gå till objekt</A> <Icon name="chevron" right />
            </div>
        )
    }

    getColumns = () => {

        let columnContractSigned = {
            name: '',
            accessor: 'contractsSigned',
            accessorRenderer: this.renderSignedStatus,
            maxWidth: 30,
        };

        let columnObjectNumber = {
            name: 'Bostadsnr',
            accessor: 'residenceObjectNumber',
            accessorRenderer: this.renderWithStatus,
            maxWidth: 120,
        };

        let columnObjectFacts = {
            name: 'Bostadsfakta',
            accessor: 'livingArea',
            accessorRenderer: this.renderFacts,
            maxWidth: 150,
        };

        let columnObjectStatus = {
            name: 'Status',
            accessor: 'statusValue',
            accessorRenderer: this.renderStatus,
            minWidth: 190,
        };

        let columnInhabitDate = {
            name: 'Tillträdesdag',
            accessor: 'accessDate',
            accessorRenderer: this.renderAsDate,
            maxWidth: 150,
        };

        let columnInhabitDateOriginal = {
            name: 'Ursprunglig tillträdesdag',
            accessor: 'accessDateOriginal',
            accessorRenderer: this.renderAsDate,
            maxWidth: 180,
        };

        let columnGotToObject = {
            name: '',
            accessor: 'columnObjectNumber',
            accessorRenderer: this.renderObjectLink,
            maxWidth: 120,
        };

        let columnParkingSpace = {
            name: 'Parkeringsplats',
            accessor: 'residenceObjectNumber',
            maxWidth: 180,
        }

        let columnRelatedObject = {
            name: 'Bostad',
            accessor: 'relatedObjectNumber',
            maxWidth: 180,
        }

        if(this.props.currentActiveFilter === ContractFilter.OBJECTS) {
            return [
                columnContractSigned,
                columnObjectNumber,
                columnObjectFacts,
                columnObjectStatus,
                columnInhabitDate,
                columnInhabitDateOriginal,
                columnGotToObject,
            ];
        } else {
            return [
                columnContractSigned,
                columnParkingSpace,
                columnRelatedObject,
                columnObjectStatus,
                columnGotToObject,
            ];
        }


    };

    getChildColumns = () => {

        let contractStatus = {
            name: 'Kontrakts status',
            accessor: 'signedByAllParties',
            maxWidth: 140,
            accessorRenderer: this.renderChildStatus,
        }

        let contractType = {
            name: '',
            accessor: 'contractTypeValue',
            maxWidth: 220,
        }

        return [
            contractStatus,
            contractType,
        ];
    }



    render() {

        let columns = this.getColumns();
        let childColumns = this.getChildColumns();

        return (
            <div className="archive-table">

                <div className="archive-table__header">
                    <h4>{this.props.currentActiveFilter === ContractFilter.OBJECTS ? 'Bostäder och avtal' : 'Parkeringsplatser'}</h4>

                    <div className="archive-table__statuses">
                        <div className="archive-table__status-column">
                            <div className="archive-table__status done" /> Signerat och klart
                        </div>

                        <div className="archive-table__status-column">
                            <div className="archive-table__status pending" /> Ej signerat av alla parter
                        </div>
                    </div>

                </div>

                <DataTable
                    data={this.props.data}
                    defaultSorted={this._defaultSorted}
                    onRowClick={this.onRowClick}
                    columns={columns}
                    childRowAccessor="residenceObjectContracts"
                    childRowColumns={childColumns}
                    showAll
                />

            </div>
        )
    }
}

export const ArchiveTable = withRouter(ArchiveTableComp);
