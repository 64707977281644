import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const Plot: React.SFC<IconProps> = (props) => {
    const {height, width} = props;

    return (
        <IconBase viewPortWidth={27} viewPortHeight={30} height={height} width={width}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-3.000000, 0.000000)" fill="currentColor" fillRule="nonzero">
                    <g transform="translate(3.000000, 0.000000)">
                        <path d="M15.5117647,14.1176471 L15.5117647,0 L0,0 L0,30 L26.4705882,30 L26.4705882,14.1176471 L15.5117647,14.1176471 Z M25.5882353,18.7455882 L21.8558824,15 L25.5882353,15 L25.5882353,18.7455882 Z M14.6294118,7.77794118 L7.71617647,0.882352941 L14.6294118,0.882352941 L14.6294118,7.77794118 Z M6.46764706,0.882352941 L14.6294118,9.04411765 L14.6294118,15 L20.6073529,15 L25.5882353,19.9941176 L25.5882353,28.8176471 L0.882352941,4.11176471 L0.882352941,0.882352941 L6.46764706,0.882352941 Z M0.882352941,15.4058824 L14.6073529,29.1176471 L5.78382353,29.1176471 L0.882352941,24.2161765 L0.882352941,15.4058824 Z M0.882352941,14.1573529 L0.882352941,5.33382353 L24.6661765,29.1176471 L15.8426471,29.1176471 L0.882352941,14.1573529 Z M0.882352941,25.4647059 L4.54852941,29.1176471 L0.882352941,29.1176471 L0.882352941,25.4647059 Z" />
                    </g>
                </g>
            </g>
        </IconBase>
    );
};
