import * as React from 'react';
import {IconBase} from 'components/atoms/Icons/_iconBase';
import {IconProps} from 'components/atoms/Icons/types';

export const Favorite: React.SFC<IconProps> = (props) => {
    const {height, width} = props;

    return (
        <IconBase fill viewPortWidth={20} viewPortHeight={20} height={height} width={width}>
            <g fill="#3F3F3C">
                <g>
                    <path d="M10,19.8347826 L1.66666667,10.5449275 C0.675362973,9.38030782 0.133011147,7.8735646 0.144444444,6.31594203 C0.144444444,2.91304348 2.67222222,0.144927536 5.77777778,0.144927536 C7.4134877,0.155026229 8.96037827,0.922807843 10,2.24057971 C11.0421032,0.919697112 12.5937285,0.151586786 14.2333333,0.144927536 C17.3388889,0.144927536 19.8666667,2.91304348 19.8666667,6.31594203 C19.8765821,7.87798984 19.3301369,9.3882034 18.3333333,10.5536232 L18.2416667,10.6637681 L10,19.8347826 Z M5.77777778,1.30434783 C3.27777778,1.30434783 1.25555556,3.55362319 1.25555556,6.31594203 C1.24979495,7.59236383 1.69818614,8.82560098 2.51388889,9.77681159 L2.59444444,9.87536232 L10,18.1362319 L17.5,9.7884058 C18.3212074,8.83315711 18.7726834,7.59340597 18.7666667,6.31014493 C18.7666667,3.54782609 16.7388889,1.29855072 14.2444444,1.29855072 C12.7069982,1.31228971 11.2855561,2.15421977 10.4861111,3.52463768 L10,4.31014493 L9.53055556,3.53043478 C8.73215066,2.1617581 7.31324016,1.32008339 5.77777778,1.30434783 Z" />
                </g>
            </g>
        </IconBase>
    );
};
