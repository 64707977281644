import * as React from 'react';

import classnames from 'classnames';

import './style.scss';

interface Props {
    children: any;
    id?:string;
    className?: string;
    center?: boolean;
}

export class H1 extends React.PureComponent<Props> {
    render() {

        let classes = classnames(this.props.className, {
            'center': this.props.center,
        });

        return (
            <h1 id={this.props.id} className={classes}>
                { this.props.children }
            </h1>
        )
    }
}