import * as React from 'react';
import {Container} from 'components/atoms/Container';
import {H2} from "../../atoms/H2";
import {P} from "../../atoms/P";
import {A} from "../../atoms/A";

import './style.scss';

interface Props {
}

export default class SigningCanceledPage extends React.PureComponent<Props> {

    render() {
        return (
            <Container>
                <div className="content">
                    <H2 center>Signeringen avbröts.</H2>
                    <P>
                        <A href="/">Tillbaka till Brf webben</A>
                    </P>
                </div>

            </Container>
        )
    }

}