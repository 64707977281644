import * as React from 'react';

import {
    Link,
    NavLink
} from 'react-router-dom';
import classnames from 'classnames';

import './style.scss';

interface Props {
    children: any;
    id?: string;
    className?: string;
    href: string;
    target?: string;
    title?: string;
    onClick?: (e: any) => any;
    darkBg?: boolean;
    textColor?: boolean;
    noStyle?: boolean;
    route?: boolean;
    navLink?: boolean;
    exact?: boolean;
    isActive?: any;
    attachedEvent?: () => any;
    'data-test-id'?: string;
}

export class A extends React.PureComponent<Props> {

    internalOnClick = (e: any) => {
        /*
        if(!this.props.route) {

            if(this.props.target === '_blank') {
                //window.open(this.props.href, '_blank');
            }
        }
        */

        if(this.props.attachedEvent) {
            this.props.attachedEvent();
        }


        this.props.onClick && this.props.onClick(e);
    };

    render() {

        let aClasses = classnames(this.props.className, {
            'dark-bg': this.props.darkBg,
            'text-color': this.props.textColor,
            'no-styles': this.props.noStyle,
        });

        if(this.props.navLink) {
            return (
                <NavLink to={this.props.href} exact={this.props.exact} activeClassName="active" onClick={this.props.onClick} className={aClasses} title={this.props.title} data-test-id={this.props['data-test-id']}>
                    {this.props.children}
                </NavLink>
            )
        }

        if(this.props.route) {
            return (
                <Link to={this.props.href} onClick={this.props.onClick} className={aClasses} title={this.props.title} data-test-id={this.props['data-test-id']}>
                    {this.props.children}
                </Link>
            )
        }

        return (
            <a 
                id={this.props.id} 
                target={this.props.target ? 
                    this.props.target : 
                    this.props.href.indexOf('http') > -1 ? 
                        "_blank" : 
                        "_self"
                    } 
                className={aClasses} 
                onClick={this.internalOnClick} 
                href={this.props.href} 
                title={this.props.title} 
                data-test-id={this.props['data-test-id']}
            >
                { this.props.children }
            </a>
        )
    }
}
