import * as React from 'react';
import {IconBase} from 'components/atoms/Icons/_iconBase';
import {IconProps} from 'components/atoms/Icons/types';

export const MapMobile: React.SFC<IconProps> = (props) => {
    const { height, width } = props;

    return (
        <IconBase fill viewPortWidth={40} viewPortHeight={40} height={height} width={width}>
            <g>
                <circle fill="none" cx="20" cy="20" r="20"/>
                <g transform="translate(13.000000, 10.000000)" fill="#FFFFFF" stroke="#FFFFFF">
                    <g>
                        <path d="M7.10144928,19.2753623 C2.36714976,13.7741017 -2.91322522e-13,9.7161307 -2.91322522e-13,7.10144928 C-2.91322522e-13,3.17942714 3.17942714,1.56319402e-13 7.10144928,1.56319402e-13 C11.0234714,1.56319402e-13 14.2028986,3.17942714 14.2028986,7.10144928 C14.2028986,9.7161307 11.8357488,13.7741017 7.10144928,19.2753623 Z M7.10144928,12.1594203 C9.89488953,12.1594203 12.1594203,9.89488953 12.1594203,7.10144928 C12.1594203,4.30800902 9.89488953,2.04347826 7.10144928,2.04347826 C4.30800902,2.04347826 2.04347826,4.30800902 2.04347826,7.10144928 C2.04347826,9.89488953 4.30800902,12.1594203 7.10144928,12.1594203 Z" strokeWidth="2"/>
                    </g>
                </g>
            </g>
        </IconBase>
    );
};
