import * as React from 'react';

import classnames from 'classnames';

import './style.scss';

interface Props {
    children: any;
    id?:string;
    className?: string;
    largeMargin?: boolean;
    sans?: boolean;
    center?: boolean;
}

export class H3 extends React.PureComponent<Props> {
    render() {

        let headerClasses = classnames(this.props.className, {
            'large-margin': this.props.largeMargin,
            'center': this.props.center,
            'sans': this.props.sans
        });

        return (
            <h3 id={this.props.id} className={headerClasses}>
                { this.props.children }
            </h3>
        )
    }
}
