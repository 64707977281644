import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const Terraced: React.SFC<IconProps> = (props) => {
    const {height, width} = props;

    return (
        <IconBase viewPortWidth={33} viewPortHeight={30} height={height} width={width}>
            <g strokeWidth="0" fill="none" fillRule="evenodd">
                <g fill="currentColor">
                    <g>
                        <path d="M32.0913043,9.02173913 L23.3304348,0.260869565 L16.1130435,7.47391304 L8.9,0.260869565 L0.134782609,9.02173913 L0.752173913,9.63913043 L1.25217391,9.13043478 L1.25217391,30 L30.9652174,30 L30.9652174,9.13043478 L31.4782609,9.64782609 L32.0913043,9.02173913 Z M10.4347826,29.1304348 L7.34347826,29.1304348 L7.34347826,23.5826087 L10.4347826,23.5826087 L10.4347826,29.1304348 Z M11.3043478,29.1304348 L11.3043478,22.7130435 L6.47391304,22.7130435 L6.47391304,29.1304348 L2.12608696,29.1304348 L2.12608696,8.26086957 L8.9,1.48695652 L15.6521739,8.26086957 L15.6521739,29.1304348 L11.3043478,29.1304348 Z M24.8652174,29.1304348 L21.773913,29.1304348 L21.773913,23.5826087 L24.873913,23.5826087 L24.8652174,29.1304348 Z M30.0826087,29.1304348 L25.7347826,29.1304348 L25.7347826,22.7130435 L20.9043478,22.7130435 L20.9043478,29.1304348 L16.5565217,29.1304348 L16.5565217,8.26086957 L23.3304348,1.48695652 L30.0956522,8.26086957 L30.0826087,29.1304348 Z" />
                    </g>
                </g>
            </g>
        </IconBase>
    );
};
