export enum ENV {
    LOCAL = 'local',
    DEV = 'dev',
    TEST = 'test',
    PROD = 'prod',
}

export const getCurrentEnv = () => {
    switch (window.location.host) {
        case 'localhost:3000':
        case 'brfweb.jmapi.io':
            return ENV.LOCAL;
        case 'brf-web-dev.azureedge.net':
            return ENV.DEV;
        case 'brf-web-test.azureedge.net':
            return ENV.TEST;
        case 'brf-web-prod.azureedge.net':
            return ENV.PROD;
        case 'dsbrf.jm.se':
            return ENV.PROD;
    }
    return '';
}

export const getApiURL = () => {
    let env = getCurrentEnv();

    switch (env) {
        case ENV.LOCAL:
            //return 'https://localhost:44349';
            return 'https://jm-brf-api-dev.azurewebsites.net';
        case ENV.DEV:
            return 'https://jm-brf-api-dev.azurewebsites.net';
        case ENV.TEST:
            return 'https://jm-brf-api-test.azurewebsites.net';
        case ENV.PROD:
            return 'https://jm-brf-api-prod.azurewebsites.net';
    }
};
