import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const Pause: React.SFC<IconProps> = (props) => {
    const {width, height} = props;

    return (
        <IconBase viewPortWidth={20} viewPortHeight={20} height={height} width={width}>
            <g stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
                <g fill="#3F3F3C">
                    <rect x="14" y="0" width="4" height="20" rx="1"/>
                    <rect x="3" y="0" width="4" height="20" rx="1"/>
                </g>
            </g>
        </IconBase>
    );
};
