import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const KopAnmalan: React.SFC<IconProps> = (props) => {
    const {height, width} = props;

    return (
        <IconBase viewPortWidth={27} viewPortHeight={30} height={height} width={width} >
            <g stroke="none" strokeWidth="1" fill="#3F3F3C" fillRule="evenodd">
                <path d="M22.1382353,1.76470588 L22.1382353,0 L21.2558824,0 L21.2558824,1.76470588 L13.7073529,1.76470588 L13.7073529,0 L12.825,0 L12.825,1.76470588 L5.29411765,1.76470588 L5.29411765,0 L4.41176471,0 L4.41176471,1.76470588 L0,1.76470588 L0,30 L26.4705882,30 L26.4705882,1.76470588 L22.1382353,1.76470588 Z M4.41176471,2.64705882 L4.41176471,4.41176471 L5.29411765,4.41176471 L5.29411765,2.64705882 L12.825,2.64705882 L12.825,4.41176471 L13.7073529,4.41176471 L13.7073529,2.64705882 L21.2558824,2.64705882 L21.2558824,4.41176471 L22.1382353,4.41176471 L22.1382353,2.64705882 L25.5882353,2.64705882 L25.5882353,7.05882353 L0.882352941,7.05882353 L0.882352941,2.64705882 L4.41176471,2.64705882 Z M0.882352941,29.1176471 L0.882352941,7.94117647 L25.5882353,7.94117647 L25.5882353,29.1176471 L0.882352941,29.1176471 Z"></path>
                <path d="M15.525,15.6220588 L13.2352941,10.9852941 L10.9455882,15.6220588 L5.82794118,16.3676471 L9.53382353,19.9764706 L8.65147059,25.0720588 L13.2352941,22.6676471 L17.8102941,25.0720588 L16.9279412,19.9764706 L20.6338235,16.3676471 L15.525,15.6220588 Z M16.6411765,23.4573529 L13.2352941,21.6705882 L9.82941176,23.4573529 L10.4823529,19.6676471 L7.725,16.9808824 L11.5323529,16.4294118 L13.2352941,12.9794118 L14.9382353,16.4294118 L18.7455882,16.9808824 L15.9882353,19.6676471 L16.6411765,23.4573529 Z"></path>
            </g>
        </IconBase>
    );
};
