import * as React from 'react';

import './style.scss';

interface Props {
    htmlFor: string;
    id?: string;
    large?: boolean;
}

export class Label extends React.PureComponent<Props> {
    render() {
        return (
            <label
                className={this.props.large ? "form-label form-label--large" : "form-label"}
                id={this.props.id}
                htmlFor={this.props.htmlFor}
            >
                {this.props.children}
            </label>
        )
    }
}
