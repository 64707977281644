import * as React from 'react';
import {IconBase} from 'components/atoms/Icons/_iconBase';
import {IconProps} from 'components/atoms/Icons/types';

export const ArrowLink: React.SFC<IconProps> = (props) => {
    const {height, width} = props;

    return (
        <IconBase fill viewPortWidth={39} viewPortHeight={26} height={height} width={width}>
            <polygon points="38.84375 10.9166667 8.58489583 10.9166667 16.2296875 3.4375 13.21875 0.5 0.40625 13 13.21875 25.5 16.2296875 22.5625 8.58489583 15.0833333 38.84375 15.0833333"/>
        </IconBase>
    );
};
