import * as React from 'react';
import {IconBase} from 'components/atoms/Icons/_iconBase';
import {IconProps} from 'components/atoms/Icons/types';

export const User: React.SFC<IconProps> = (props) => {
    const {height, width} = props;

    return (
        <IconBase viewPortWidth={20} viewPortHeight={20} height={height} width={width}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g fill="#3F3F3C">
                    <g>
                        <path d="M6.13809533,9.94541417 C5.0419744,8.90006211 4.3537415,7.39156525 4.3537415,5.71428571 C4.3537415,2.55837286 6.79028708,7.61295788e-15 9.79591837,7.10542736e-15 C12.8015497,6.59789683e-15 15.2380952,2.55837286 15.2380952,5.71428571 C15.2380952,7.39182705 14.5496475,8.90053301 13.4532281,9.94590363 C17.0237003,11.5778841 19.3500722,15.300583 19.3197279,19.4285714 L19.3197279,20 L0.272108844,20 L0.272108844,19.4285714 C0.233453175,15.2918937 2.5681687,11.5680112 6.13809533,9.94541417 Z M7.20136087,10.7386069 C3.8605095,11.9379667 1.55290254,15.1541872 1.37687075,18.8571429 L18.214966,18.8571429 C18.0359936,15.1990934 15.7831784,12.0113599 12.5006803,10.7714286 L12.4408163,10.7485714 L12.4084756,10.7282911 C11.6330042,11.1747153 10.7426952,11.4285714 9.79591837,11.4285714 C8.85645848,11.4285714 7.97259705,11.1786239 7.20136087,10.7386069 Z M9.79591837,1.14285714 C7.39141333,1.14285714 5.44217687,3.18955543 5.44217687,5.71428571 C5.44217687,8.239016 7.39141333,10.2857143 9.79591837,10.2857143 C12.2004234,10.2857143 14.1496599,8.239016 14.1496599,5.71428571 C14.1496599,4.50186748 13.6909634,3.33910673 12.8744785,2.48179757 C12.0579936,1.62448841 10.9506024,1.14285714 9.79591837,1.14285714 Z" />
                    </g>
                </g>
            </g>
        </IconBase>
    );
};
