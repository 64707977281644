import * as React from 'react';
import {Container} from 'components/atoms/Container';

import './style.scss';
import {BrfTable} from "components/organisms/tables/BrfTable";
import {Message} from "components/atoms/Message";
import {DocumentsConsumer, DocumentsContextType} from 'services/documents/documents-context';
import {CooperativeModel} from 'services/documents/types';
import {Loader} from "../../atoms/Loader";
import { H1 } from 'components/atoms/H1';

interface Props {

}

export class StartPage extends React.PureComponent<Props> {

    getContractLength = (models: CooperativeModel[]) => {
            return models && models.map(x => x.contractTasks).reduce((acc, val) => acc.concat(val), []).length;
    };

    render() {

        return (
            <div className="start-page">
                <DocumentsConsumer>
                    {(documents: DocumentsContextType) => {

                        if(documents.loading) return <Loader text=""/>;

                        return (
                            <Container>

                                <H1 center>Dina bostadsrättsföreningar</H1>

                                <div className="start-page__table-container">
                                    <Message info centered>
                                        Du har {documents.models.length > 0 ? this.getContractLength(documents.models) : 0} nya avtal att signera
                                    </Message>
                                </div>

                                {documents.models.map((model, index) => (
                                    <div className="start-page__table-container" key={`cooperative-${index}`}>
                                        <BrfTable title={model.cooperativeName} cooperativeId={model.cooperativeId} key={`cooperative-${model.cooperativeName}-${index}`} data={model.contractTasks} />
                                    </div>
                                ))}

                            </Container>
                        )
                    }}
                </DocumentsConsumer>
            </div>
        )
    }
}
