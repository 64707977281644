import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const SunstudyHalfSun: React.SFC<IconProps> = (props) => {
    const {width, height} = props;

    return (
        <IconBase fill viewPortWidth={20} viewPortHeight={20} height={height} width={width}>
            <g stroke="none" fill="currentColor">
                <path d="M5.96527449,15.3333333 C5.82658075,14.9140464 5.75151515,14.4657894 5.75151515,14 C5.75151515,11.6569738 7.65091318,9.75757576 9.99393939,9.75757576 C12.3369656,9.75757576 14.2363636,11.6569738 14.2363636,14 C14.2363636,14.4573743 14.1625067,14.9071337 14.0213976,15.3333333 L5.96527449,15.3333333 Z M9.39393939,4 L10.6060606,4 L10.6060606,8.54545455 L9.39393939,8.54545455 L9.39393939,4 Z M7.16315895e-13,13.3939394 L4.54545455,13.3939394 L4.54545455,14.6060606 L7.16315895e-13,14.6060606 L7.16315895e-13,13.3939394 Z M15.4545455,13.3939394 L20,13.3939394 L20,14.6060606 L15.4545455,14.6060606 L15.4545455,13.3939394 Z M2.50209081,7.35659714 L3.35918994,6.49949801 L6.57331167,9.71361975 L5.71621254,10.5707189 L2.50209081,7.35659714 Z M13.4288395,9.71414383 L16.6429612,6.5000221 L17.5000603,7.35712122 L14.2859386,10.571243 L13.4288395,9.71414383 Z"></path>
            </g>
        </IconBase>
    );
};
