import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const SunstudyMoonOutline: React.SFC<IconProps> = (props) => {
    const {width, height} = props;

    return (
        <IconBase fill viewPortWidth={20} viewPortHeight={20} height={height} width={width}>
            <g fill="#3F3F3C" fillRule="nonzero">
                <path d="M10.3846154,19.9269231 C5.49622116,19.921218 1.28096996,16.4900131 0.283329297,11.7044991 C-0.714311362,6.91898515 1.77878116,2.08928542 6.25769231,0.130769231 C6.5499302,0.0010860002 6.89191794,0.0656905233 7.11670824,0.293044972 C7.34149854,0.520399421 7.40222016,0.863097901 7.26923077,1.15384615 C5.82539483,4.46874194 6.55712882,8.32951684 9.11380599,10.886194 C11.6704832,13.4428712 15.5312581,14.1746052 18.8461538,12.7307692 C19.1361482,12.6028866 19.4748927,12.6659585 19.6994277,12.8896429 C19.9239627,13.1133274 19.9883197,13.4518301 19.8615385,13.7423077 C18.2153985,17.5065945 14.4930888,19.9357641 10.3846154,19.9269231 L10.3846154,19.9269231 Z M5.22307692,2.48846154 C4.85393338,2.75816061 4.50552173,3.05514619 4.18076923,3.37692308 C0.744920835,6.81277147 0.744920835,12.3833824 4.18076923,15.8192308 C7.61661763,19.2550792 13.1872285,19.2550792 16.6230769,15.8192308 C16.9448538,15.4944783 17.2418394,15.1460666 17.5115385,14.7769231 C14.0780641,15.5166101 10.503844,14.4632243 8.02030985,11.9796902 C5.53677573,9.49615603 4.48338986,5.92193595 5.22307692,2.48846154 L5.22307692,2.48846154 Z"></path>
            </g>
        </IconBase>
    );
};
