import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const Facebook: React.SFC<IconProps> = (props) => {
    const {width, height} = props;

    return (
        <IconBase fill viewPortWidth={121} viewPortHeight={121} height={height} width={width}>
            <g>
                <defs>
                    <path id="facebook-1" d="M.007.005H121v120.26H.007z"/>
                </defs>
                <g fill="none" fillRule="evenodd">
                    <mask id="facebook-2" fill="#fff">
                        <use xlinkHref="#facebook-1"/>
                    </mask>
                    <path fill="#1877F2" d="M121 60.5C121 27.087 93.913 0 60.5 0S0 27.087 0 60.5c0 30.197 22.124 55.226 51.047 59.765V77.988H35.686V60.5h15.36V47.171c0-15.163 9.033-23.538 22.853-23.538 6.619 0 13.542 1.181 13.542 1.181v14.89h-7.629c-7.515 0-9.859 4.663-9.859 9.447V60.5h16.78L84.05 77.988H69.953v42.277C98.876 115.726 121 90.697 121 60.5" mask="url(#facebook-2)"/>
                    <path fill="#FFF" d="M84.05 77.988L86.732 60.5H69.953V49.151c0-4.784 2.344-9.448 9.86-9.448h7.628V24.814s-6.923-1.181-13.542-1.181c-13.82 0-22.852 8.375-22.852 23.538V60.5H35.686v17.488h15.36v42.277c3.081.483 6.238.735 9.454.735s6.373-.252 9.453-.735V77.988H84.05"/>
                </g>
            </g>
        </IconBase>
    );
};
