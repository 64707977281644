import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const SunStudyControl: React.SFC<IconProps> = (props) => {
    const {height, width} = props;

    return (
        <IconBase viewPortWidth={54} viewPortHeight={48} height={height} width={width}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(0.000000, 1.000000)">
                    <g>
                        <path d="M6.23657376,32.1829534 C9.83321783,40.3152189 18.0610704,46 27.6359174,46 C37.4243541,46 45.8050302,40.0587651 49.2690059,31.6351073 M49.1052462,13.9769474 C45.5462016,5.75939637 37.2733967,0 27.6359174,0 C17.844815,0 9.4622401,5.94447133 6,14.3717753" stroke="#3F3F3C"/>
                        <path d="M49.1052462,13.9769474 C45.5462016,5.75939637 37.2733967,6.21724894e-15 27.6359174,6.21724894e-15 C17.844815,6.21724894e-15 9.4622401,5.94447133 6,14.3717753"  stroke="#3F3F3C"/>
                        <g transform="translate(0.000000, 17.000000)" fill="#DAA63E">
                            <g transform="translate(0.003939, 0.000000)">
                                <path d="M6.49606061,9.25757576 C4.97309357,9.25757576 3.73848485,8.02296704 3.73848485,6.5 C3.73848485,4.97703296 4.97309357,3.74242424 6.49606061,3.74242424 C8.01902764,3.74242424 9.25363636,4.97703296 9.25363636,6.5 C9.25363636,7.23135456 8.96310689,7.93275475 8.44596112,8.44990052 C7.92881535,8.96704629 7.22741517,9.25757576 6.49606061,9.25757576 Z M6.10606061,-4.17443857e-14 L6.89393939,-4.17443857e-14 L6.89393939,2.95454545 L6.10606061,2.95454545 L6.10606061,-4.17443857e-14 Z M6.10606061,10.0454545 L6.89393939,10.0454545 L6.89393939,13 L6.10606061,13 L6.10606061,10.0454545 Z M4.13002965e-13,6.10606061 L2.95454545,6.10606061 L2.95454545,6.89393939 L4.13002965e-13,6.89393939 L4.13002965e-13,6.10606061 Z M10.0454545,6.10606061 L13,6.10606061 L13,6.89393939 L10.0454545,6.89393939 L10.0454545,6.10606061 Z M1.62635903,2.18178814 L2.18347346,1.62467371 L4.27265259,3.71385283 L3.71553815,4.27096727 L1.62635903,2.18178814 Z M8.72840499,9.28648511 L9.28551943,8.72937068 L11.3746986,10.8185498 L10.8175841,11.3756642 L8.72840499,9.28648511 Z M1.62601837,10.8182092 L3.7151975,8.72903002 L4.27231193,9.28614446 L2.18313281,11.3753236 L1.62601837,10.8182092 Z M8.72874565,3.71419349 L10.8179248,1.62501436 L11.3750392,2.1821288 L9.28586008,4.27130792 L8.72874565,3.71419349 Z" />
                            </g>
                        </g>
                        <g transform="translate(49.355434, 22.355434) rotate(19.000000) translate(-49.355434, -22.355434) translate(44.355434, 17.355434)" fill="#3F3F3C">
                            <g>
                                <path d="M5.19230769,9.96346154 C2.74811058,9.96060899 0.640484978,8.24500653 0.141664648,5.85224955 C-0.357155681,3.45949258 0.889390582,1.04464271 3.12884615,0.0653846154 C3.2749651,0.000543000099 3.44595897,0.0328452617 3.55835412,0.146522486 C3.67074927,0.26019971 3.70111008,0.43154895 3.63461538,0.576923077 C2.91269741,2.23437097 3.27856441,4.16475842 4.55690299,5.44309701 C5.83524158,6.72143559 7.76562903,7.08730259 9.42307692,6.36538462 C9.56807409,6.30144329 9.73744637,6.33297923 9.84971385,6.44482146 C9.96198133,6.55666369 9.99415983,6.72591507 9.93076923,6.87115385 C9.10769923,8.75329725 7.24654441,9.96788205 5.19230769,9.96346154 L5.19230769,9.96346154 Z" fill-rule="nonzero"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </IconBase>
    );
};
