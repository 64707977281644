import * as React from 'react';
import {IconProps} from 'components/atoms/Icons/types';
import {IconBase} from 'components/atoms/Icons/_iconBase';

export const PaymentVipps
: React.SFC<IconProps> = (props) => {
    const {width, height} = props;

    return (
        <IconBase fill viewPortWidth={263} viewPortHeight={67} height={height} width={width}>
            <path fill="#FF5B24" fillRule="evenodd" d="M262.5 16.618C259.546 5.348 252.375.875 242.586.875c-7.931 0-17.885 4.473-17.885 15.246 0 6.96 4.808 12.43 12.654 13.839l7.425 1.325c5.063.91 6.498 2.817 6.498 5.386 0 2.9-3.122 4.557-7.763 4.557-6.074 0-9.871-2.154-10.462-8.204l-10.715 1.658c1.686 11.683 12.148 16.49 21.598 16.49 8.945 0 18.479-5.139 18.479-15.495 0-7.044-4.304-12.18-12.32-13.674l-8.183-1.49c-4.557-.829-6.075-3.066-6.075-5.22 0-2.735 2.952-4.474 7.003-4.474 5.147 0 8.775 1.739 8.943 7.456l10.717-1.657zM24.302 34.35L35.437 2.117h13.078l-19.407 47.81h-9.702L0 2.118h13.078L24.302 34.35zM92.73 15.79c0 3.81-3.038 6.463-6.582 6.463s-6.58-2.652-6.58-6.463c0-3.812 3.036-6.463 6.58-6.463s6.583 2.65 6.583 6.463h-.001zm2.024 16.904c-4.388 5.633-9.028 9.527-17.213 9.528-8.35 0-14.85-4.972-19.911-12.263-2.026-2.984-5.148-3.646-7.426-2.072-2.11 1.492-2.614 4.64-.675 7.376 7.003 10.523 16.706 16.653 28.011 16.653 10.38 0 18.48-4.97 24.807-13.257 2.362-3.065 2.278-6.214 0-7.954-2.11-1.659-5.232-1.076-7.593 1.989zm29.113-6.796c0 9.779 5.738 14.916 12.15 14.916 6.075 0 12.32-4.806 12.32-14.916 0-9.944-6.245-14.749-12.236-14.749-6.496 0-12.234 4.558-12.234 14.75zm0-17.15V2.2h-11.896v64.3h11.896V43.63c3.966 5.304 9.113 7.542 14.934 7.542 10.886 0 21.516-8.452 21.516-25.853 0-16.656-11.053-24.443-20.504-24.443-7.509 0-12.655 3.396-15.946 7.872zm57.125 17.15c0 9.779 5.737 14.916 12.15 14.916 6.074 0 12.317-4.806 12.317-14.916 0-9.944-6.243-14.749-12.234-14.749-6.496 0-12.234 4.558-12.234 14.75zm0-17.15V2.2h-11.897v64.3h11.896V43.63c3.966 5.304 9.112 7.542 14.934 7.542 10.885 0 21.516-8.452 21.516-25.853 0-16.656-11.053-24.443-20.504-24.443-7.51 0-12.655 3.396-15.945 7.872z"/>
        </IconBase>
    );
};
