import * as React from 'react';
import {Container} from 'components/atoms/Container';

import './style.scss';
import {ArchiveTable} from "../../organisms/tables/ArchiveTable";
import {Button} from "../../atoms/Button";
import {getObjects} from 'services/documents/documents-service';
import {CooperativeArchiveModel} from 'services/documents/types';
import {Loader} from "../../atoms/Loader";
import {H3} from "../../atoms/H3";
import {A} from "../../atoms/A";

interface Props {

}

interface State {
    model: CooperativeArchiveModel | null;
    currentFilter: ContractFilter;
}

export enum ContractFilter {
    OBJECTS = 'objects',
    PARKINGS = 'parkings',
    STORAGE = 'storage',
}

export class ArchivePage extends React.PureComponent<Props, State> {

    state = {
        model: null,
        currentFilter: ContractFilter.OBJECTS,
    };

    async componentWillMount() {
        //@ts-ignore
        let response: CooperativeArchiveModel = await getObjects(this.props.computedMatch.params.cooperativeId);

        this.setState({
            model: response,
        });
    }

    setCurrentFilter = (filter: ContractFilter) => (e) => {
        e.preventDefault();

        this.setState({
            currentFilter: filter,
        })
    }

    getFilteredData = () => {
        switch (this.state.currentFilter) {
            case ContractFilter.OBJECTS:
                return this.state.model.residenceObjects.filter(x => {
                    let hit = x.residenceObjectContracts.filter((x) => x.contractType !== 155330006 )

                    return hit.length > 0;
                })

            case ContractFilter.PARKINGS:

                return this.state.model.residenceObjects.filter(x => {
                    let hit = x.residenceObjectContracts.filter((x) => x.contractType === 155330006 )

                    return hit.length > 0;
                })
            default:
                return this.state.model.residenceObjects;
        }
    }

    render() {

        if(!this.state.model) return <Loader text=""/>;

        let filteredObjects = this.getFilteredData();


        return (
            <div className="archive-page">
                <Container>
                    <H3>{this.state.model.cooperativeName}</H3>

                    <Button href="/">Tillbaka</Button>

                    <ul className="archive-page__filter">
                        <li className={this.state.currentFilter === ContractFilter.OBJECTS ? 'active' : ''}>
                            <A href="#" onClick={this.setCurrentFilter(ContractFilter.OBJECTS)}>Bostäder och avtal</A>
                        </li>
                        <li className={this.state.currentFilter === ContractFilter.PARKINGS ? 'active' : ''}>
                            <A href="#" onClick={this.setCurrentFilter(ContractFilter.PARKINGS)}>Parkeringsplatser</A>
                        </li>
                        {/*
                        <li className={this.state.currentFilter === ContractFilter.STORAGE ? 'active' : ''}>
                            <A href="#" onClick={this.setCurrentFilter(ContractFilter.STORAGE)}>Förråd</A>
                        </li>
                        */}
                    </ul>

                    <ArchiveTable
                        cooperativeId={this.state.model.cooperativeId}
                        data={filteredObjects}
                        currentActiveFilter={this.state.currentFilter}
                    />

                    <ul className="archive-page__filter">
                        <li className={this.state.currentFilter === ContractFilter.OBJECTS ? 'active' : ''}>
                            <A href="#" onClick={this.setCurrentFilter(ContractFilter.OBJECTS)}>Bostäder och avtal</A>
                        </li>
                        <li className={this.state.currentFilter === ContractFilter.PARKINGS ? 'active' : ''}>
                            <A href="#" onClick={this.setCurrentFilter(ContractFilter.PARKINGS)}>Parkeringsplatser</A>
                        </li>
                        {/*
                        <li className={this.state.currentFilter === ContractFilter.STORAGE ? 'active' : ''}>
                            <A href="#" onClick={this.setCurrentFilter(ContractFilter.STORAGE)}>Förråd</A>
                        </li>
                        */}
                    </ul>

                    <Button href="/">Tillbaka</Button>

                </Container>
            </div>
        )
    }
}
